<template>
  <div id="PaymentOrderSn">
    <div
      class="d-flex align-items-center justify-content-center flex-column mb-4 my-3 mx-auto"
    >
      <img src="@/assets/check-circle.png" class="iconCircle img-fluid" />
      <div class="title-payment">การชำระเงินสำเร็จ</div>
    </div>

    <div class="box-payment">
      <div
        class="alert alert-warning alert-dismissible fade show order-sn"
        role="alert"
        v-show="showAlert"
      >
        <strong>Copy Order SN </strong>{{ this.message }}
        <button type="button" class="close" @click="showAlert = !showAlert">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex order-sn mb-2" v-if="productCoach.type === 2">
        <div class="mr-3">
          <product-image
            :pathImage="orderDetail.masterImg"
            css="img-payment-success"
          ></product-image>
        </div>
        <div class="merchant-name">
          {{ orderDetail.productName }}
          <div>
            <span class="stickerPrice">฿{{ products.oldMonney }}</span>
            <span class="order-sn-copy">฿{{ stickPrice }} </span>
          </div>

          <div class="order-sn-copy">฿{{ orderDetail.voucherPrice }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-between order-sn mb-3">
        <div class="my-auto">
          เลขที่คำสั่งซื้อ :
          {{ orderDetail.ordersSn || orderDetail.orderSn || 'กรุณารอสักครู่' }}
        </div>
        <div
          class="my-auto order-sn-copy"
          v-clipboard:copy="orderDetail.ordersSn || orderDetail.orderSn"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          style="cursor: pointer"
        >
          คัดลอก
        </div>
      </div>
      <div class="d-flex justify-content-between order-sn mb-2">
        <div class="my-auto">
          เวลาสร้าง : {{ orderDetail.createTime || 'กรุณารอสักครู่' }}
        </div>
        <div class="my-auto order-sn-copy" v-if="productCoach.type === 2">
          จำนวน : {{ orderDetail.number }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentOrderSn',
  props: {
    orderDetail: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      message: '',
      showAlert: false,
    }
  },
  computed: {
    stickPrice() {
      const stickPrice = this.products.oldMonney - this.orderDetail.voucherPrice
      return parseFloat(stickPrice).toFixed(2)
    },
  },
  methods: {
    onCopy: function (e) {
      this.message = e.text
      this.showAlert = true
      console.log('You just copied: ' + e.text)
    },
    onError: function () {
      this.message = 'Failed to copy texts'
      this.showAlert = true
      console.log('Failed to copy texts')
    },
  },
}
</script>

<style scoped>
.iconCircle {
  width: 50px;
  height: 50px;
}

.stickerPrice {
  color: #aaa;
  text-decoration: line-through;
  padding-left: 3px;
}

.merchant-name {
  color: #000;
}

.title-payment {
  font-size: 26px;
}

.order-sn {
  color: #6d6d6d;
  font-size: 20px;
}

.order-sn-copy {
  color: #da1f28;
}

.box-payment {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  padding: 10px;
}

@media screen and (max-width: 480px) {
  .order-sn {
    font-size: 13px;
  }

  .title-payment {
    font-size: 20px;
  }
}
</style>
