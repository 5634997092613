<template>
  <div id="evoucherShopDetail">
    <evoucher-qrcode
      v-if="evoucherDetail.resourceList !== null"
      :evoucherDetail="evoucherDetail"
      :products="products"
    >
    </evoucher-qrcode>
    <redeem-location :evoucherDetail="evoucherDetail"></redeem-location>

    <div class="title">สิ่งที่คุณจะได้รับ</div>
    <div class="subtitle mb-2" style="white-space: pre-wrap">
      {{ evoucherDetail.serviceDesc }}
    </div>

    <div class="title">ข้อกำหนดและเงื่อนไข</div>
    <div class="subtitle mb-4" style="white-space: pre-wrap">
      {{ evoucherDetail.termsConditions }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'evoucherShopDetail',
  props: {
    evoucherDetail: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 20px;
}

.subtitle {
  font-size: 20px;
  color: #6d6d6d;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 13px;
  }
}
</style>
