<template>
  <div id="EvoucherName">
    <div class="description">
      {{ voucherDetail.productName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoucherName',
  props: {
    voucherDetail: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.description {
  font-size: 20px;
  padding-bottom: 16px;
}

.description span {
  font-size: 20px !important;
  font-family: 'Prompt', sans-serif !important;
}

@media screen and (max-width: 480px) {
  .description {
    font-size: 15px;
  }

  .description span {
    font-size: 15px !important;
  }
}
</style>
