<template>
  <div id="PaymentDownloadApp">
    <div class="box-delivery-status mb-3">
      <div class="title">
        {{
          productCoach.type === 1
            ? `การตรวจสอบสถานะการจัดส่ง`
            : `สามารถตรวจสอบ Evoucher`
        }}
      </div>
      <div class="subtitle">
        {{
          productCoach.type === 1
            ? `สามารถตรวจสอบสถานะการจัดส่งได้ที่ Application moomall`
            : `สามารถตรวจสอบ Evoucher ของคุณได้ที่ Application moomall`
        }}
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center flex-column mb-4 mx-auto"
    >
      <img src="/img/moomall.png" class="mooamll img-fluid mb-3" />

      <div class="box-download-app mb-4">
        <img
          src="/img/googleplay.png"
          class="download-app img-fluid mb-1"
          @click="googlePlayDownload()"
        />
        <img
          src="/img/appstore.png"
          class="download-app img-fluid mb-1"
          @click="appstoreDownload()"
        />
        <img
          src="/img/appgallery.png"
          class="download-app img-fluid mb-1"
          @click="appgalleryDownload()"
        />
      </div>

      <img
        src="/img/buttonDownload.png"
        class="buttonDownload img-fluid mb-3"
        @click="downloadNow()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentDownloadApp',
  props: {
    productCoach: {
      type: Object,
      required: true,
    },
  },
  methods: {
    googlePlayDownload() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.thiraventure.moomall',
      )
    },
    appstoreDownload() {
      window.open(
        'https://apps.apple.com/us/app/moomall/id1487597576?l=th&ls=1',
      )
    },
    appgalleryDownload() {
      window.open('https://appgallery.huawei.com/#/app/C102405861')
    },
    downloadNow() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/android/i.test(userAgent)) {
        return this.googlePlayDownload()
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return this.appstoreDownload()
      }

      return this.googlePlayDownload()
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 26px;
}

.subtitle {
  font-size: 20px;
  color: #6d6d6d;
}

.mooamll {
  width: 150px;
  height: 150px;
}

.buttonDownload {
  max-width: 475px;
  height: 110px;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 13px;
  }

  .download-app {
    width: 90px;
    height: 30px;
  }

  .mooamll {
    width: 90px;
    height: 90px;
  }

  .buttonDownload {
    max-width: 270px;
    height: 63px;
  }
}
</style>
