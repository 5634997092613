<template>
  <div id="OrderDescription">
    <div class="title-order mb-2">กรุณาตรวจสอบความถูกต้องของข้อมูล</div>
    <div class="card mb-1">
      <div class="card-body">
        <div class="product-sku row">
          <div class="image-sku col-5">
            <product-image
              :pathImage="
                ProductDetail(`sp_${this.products.spTimestamp}`).masterImg
              "
            ></product-image>
          </div>
          <div class="detail-sku my-auto col-7">
            <div class="order-title-name ml-4 mb-1">
              {{ ProductDetail(`sp_${this.products.spTimestamp}`).name1 }}
            </div>
            <div
              class="order-type ml-4 mb-1"
              v-for="(sItem, sIndex) in ProductDetail(
                `sp_${this.products.spTimestamp}`,
              ).specSelected"
              :key="sIndex"
            >
              เลือก {{ sItem.name }}
            </div>
            <div class="box-price d-flex justify-content-between">
              <div class="order-price ml-4 mb-1">฿{{ productPrice }}</div>
              <div class="order-price mb-1">
                x{{
                  ProductDetail(`sp_${this.products.spTimestamp}`)
                    .selectNumTotal
                }}
              </div>
            </div>
            <div class="ml-4 free-freight order-type" v-if="freeFreight > 0">
              จัดส่งฟรีเมื่อซื้อเกิน {{ freeFreight }} บาท
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as numeral from 'numeral'

export default {
  name: 'OrderDescription',
  props: {
    productCoach: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
    freeFreight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      ProductDetail: 'ProductStore/ProductDetail',
    }),
    productPrice() {
      const price = this.ProductDetail(
        `sp_${this.products.spTimestamp}`,
      ).totalPrice
      return numeral(price).format('0,0.00')
    },
  },
}
</script>

<style scoped>
.card {
  border: 0px;
  border-radius: 0px;
}

.card-body {
  font-size: 13px;
}

.order-type {
  color: #6d6d6d;
  font-size: 26px;
}

.order-title-name {
  font-size: 26px;
}

.order-price {
  color: #da2a24;
  font-size: 26px;
}

.title-order {
  font-size: 26px;
}

.free-freight {
  color: #999999;
}

@media screen and (max-width: 480px) {
  .order-type {
    font-size: 12px;
  }

  .title-order {
    font-size: 15px;
  }

  .order-price {
    font-size: 13px;
  }

  .order-title-name {
    font-size: 13px;
  }
}
</style>
