<template>
  <div class="voucher-condition container">
    <div class="voucher-condition-title mb-2">ข้อกำหนดและเงื่อนไข</div>
    <div class="voucher-condition-description">
      {{ termsConditions }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'evoucherCondition',
  props: {
    termsConditions: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.voucher-condition {
  padding: 1rem;
  border-radius: 14px;
  background-color: white;

  &-title {
    font-size: 20px;
    font-weight: bold;
    color: #bebebe;

    @media screen and (max-width: 480px) {
      font-size: 10px;
    }
  }

  &-description {
    white-space: pre-wrap;
    font-size: 22px;
    color: #453d3d;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
}
</style>
