<template>
  <div class="message">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'ProductMessage',
  props: {
    message: {
      type: String,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;

  font-weight: bold;
  font-size: 20px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}
</style>
