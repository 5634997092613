<template>
  <div class="text-center" v-if="pathImage">
    <img :src="path" class="img-fluid" :class="css" alt="review image" />
  </div>
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    pathImage: {
      type: [String, Number],
      default: '',
    },
    urlImage: {
      type: [String, Number],
      default: process.env.VUE_APP_MOOMALL_FILE_URL,
    },
    pathImageTypeArray: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    css: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    path() {
      return this.urlImage + this.pathImage
    },
  },
}
</script>

<style scoped>
.img-review {
  max-width: 100% !important;
  height: 500px !important;
}

.img-payment-success {
  max-width: 100px !important;
}

@media screen and (max-width: 480px) {
  .img-review {
    max-width: 100% !important;
    height: 343px !important;
  }

  .img-main-carousel {
    max-width: 100%;
    height: auto !important;
  }

  .img-payment-success {
    max-width: 81px !important;
  }
}
</style>
