<template>
  <div id="ProductDeliveryDescription">
    <div class="card container">
      <div class="">
        <div class="row">
          <div class="product-name col-12 cut-text">{{ productName }}</div>
        </div>

        <v-divider class="mx-1"></v-divider>

        <div class="row">
          <div class="title-delivery col-4">สเปคสินค้า</div>
          <div class="detail-delivery col-8 my-auto">{{ specInfo }}</div>
        </div>

        <v-divider class="mx-1"></v-divider>

        <div class="row">
          <div class="title-delivery col-4">ระยะเวลาจัดส่ง</div>
          <div class="detail-delivery col-8 my-auto">
            {{ productDelivery.deliveryDesc }}
          </div>
        </div>

        <v-divider class="mx-1"></v-divider>

        <div class="row">
          <div class="title-delivery col-4">นโยบาย</div>
          <div class="detail-delivery col-8 my-auto">
            {{ productDelivery.serviceDesc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDeliveryDescription',
  props: {
    productDelivery: {
      type: Object,
    },
    specInfo: {
      type: String,
      default: '',
    },
    productName: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.product-name {
  font-weight: bold;
  font-size: 20px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.cut-text {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}

.card {
  border: 0px;
  border-radius: 14px;
}

.title-delivery {
  color: #999999;
  font-size: 30px;
}

.detail-delivery {
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .title-delivery {
    font-size: 13px;
  }

  .detail-delivery {
    font-size: 13px;
  }
}
</style>
