<template>
  <div id="ProductCustomReview">
    <div class="title-review mb-2">รีวิว</div>
    <div class="card">
      <div class="card-body">
        <product-image-avatar :pathImage="reviewProfileImage">
          <template v-slot:name-avatar>
            <div class="custom-review-name d-inline">
              {{ reviewProfileName }}
            </div>
          </template>
        </product-image-avatar>
        <p class="card-text">{{ reviewText }}</p>
      </div>
      <div class="px-2">
        <product-image-carousel
          :productImageSlide="reviewImages"
        ></product-image-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCustomReview',
  props: {
    reviewProfileName: {
      type: String,
      required: true,
    },
    reviewText: {
      type: String,
    },
    reviewImages: {
      type: Array,
      default: () => [],
    },
    reviewProfileImage: {
      type: String,
    },
  },
}
</script>

<style scoped>
.card {
  background-color: #efefef;
  border: 0px;
  border-radius: 0px;
}

.title-review {
  color: #aaaaaa;
  font-size: 30px;
}

.card-body {
  font-size: 20px;
  padding: 8px;
}

.custom-review-name {
  font-size: 30px;
}

@media screen and (max-width: 480px) {
  .title-review {
    color: #aaaaaa;
    font-size: 13px;
  }

  .card-body {
    font-size: 13px;
    padding: 8px;
  }

  .custom-review-name {
    font-size: 13px;
  }
}
</style>
