<template>
  <div id="carouselProduct">
    <carousel
      v-if="productImageSlide"
      :perPageCustom="[
        [320, 1],
        [768, 1],
        [1024, 1],
      ]"
      :autoplay="true"
      :loop="true"
      data-aos="fade-up"
      data-aos-delay="400"
      data-aos-duration="1000"
      paginationActiveColor="#DA1F28"
      paginationColor="#999999"
      :paginationPadding="3"
      :autoplayTimeout="autoplayTimeout"
    >
      <slide v-for="item in productImageSlide" :key="item.id">
        <product-image
          v-if="item.imagePath && item.type !== 'main-carousel'"
          :pathImage="item.imagePath"
          css="img-review"
        >
        </product-image>
        <product-image
          v-if="item.type === 'review_images'"
          :pathImage="item.id"
          urlImage="file/"
          css="img-review"
        >
        </product-image>
        <product-video
          v-if="item.videoPath"
          :posterUrl="productImageSlide[1].imagePath"
          :videoPath="item.videoPath"
          css="product-video"
        ></product-video>
        <product-image
          v-if="item.imagePath && item.type === 'main-carousel'"
          :pathImage="item.imagePath"
          css="img-main-carousel"
        >
        </product-image>
      </slide>
    </carousel>

    <carousel
      v-if="productCommentList"
      :perPageCustom="[
        [320, 1],
        [768, 1],
        [1024, 1],
      ]"
      :autoplay="true"
      :loop="true"
      data-aos="fade-up"
      data-aos-delay="400"
      data-aos-duration="1000"
      paginationActiveColor="#DA1F28"
      paginationColor="#999999"
      :paginationPadding="3"
    >
      <slide v-for="item in productCommentList.img" :key="item.id">
        <product-image v-if="item" :pathImage="item" css="img-review">
        </product-image>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'ProductImageCarousel',
  components: {
    Carousel,
    Slide,
  },
  props: {
    productImageSlide: {
      type: Array,
    },
    productCommentList: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    // Return with millisecond
    autoplayTimeout() {
      if (this.productImageSlide) {
        const video = [...this.productImageSlide].find(
          slide => slide.type === 'video',
        )
        if (video) {
          return 7000
        }
      }
      return 2000
    },
  },
}
</script>

<style scoped></style>
