<template>
  <div id="OrderAddress">
    <div class="d-flex justify-content-between title-order-address">
      <div class="mb-2">
        {{ productCoach.type === 1 ? 'ที่อยู่สำหรับจัดส่ง' : 'ข้อมูลลูกค้า' }}
      </div>
      <div class="order-editor-address" @click="editAddress()">แก้ไข</div>
    </div>

    <div class="subtitle-order-address">
      <div class="my-auto">
        <span class="title">ชื่อ - นามสกุล</span>
        {{ AddressShipping(`sp_${this.products.spTimestamp}`).name }}
      </div>
    </div>

    <div class="d-flex justify-content-between subtitle-order-address">
      <div
        class="box-address my-auto text-break"
        v-if="productCoach.type === 1"
      >
        <div>
          <span class="title">ที่อยู่</span>
          {{ AddressShipping(`sp_${this.products.spTimestamp}`).address }}
          {{
            `เขต${
              AddressShipping(`sp_${this.products.spTimestamp}`).district.text
            }`
          }},
          {{
            `แขวง${
              AddressShipping(`sp_${this.products.spTimestamp}`).sub_district
                .text
            }`
          }},
          {{
            `จังหวัด${
              AddressShipping(`sp_${this.products.spTimestamp}`).province.text
            }`
          }},
          {{ AddressShipping(`sp_${this.products.spTimestamp}`).zipcode }}
        </div>
        <div><span class="title">หมายเลขโทรศัพท์</span> {{ phone }}</div>
        <div>
          <span class="title">อีเมล</span>
          {{ AddressShipping(`sp_${this.products.spTimestamp}`).email }}
        </div>
      </div>
      <div
        class="box-address my-auto text-break"
        v-if="productCoach.type === 2"
      >
        <div><span class="title">หมายเลขโทรศัพท์</span> {{ phone }}</div>
        <div>
          <span class="title">อีเมล</span>
          {{ AddressShipping(`sp_${this.products.spTimestamp}`).email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OrderAddress',
  props: {
    productCoach: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      AddressShipping: 'ProductStore/AddressShipping',
      PDPA: 'ProductStore/PDPA',
    }),
    phone() {
      return this.AddressShipping(
        `sp_${this.products.spTimestamp}`,
      ).tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
    },
  },
  methods: {
    ...mapMutations({
      removeUserInfo: 'ProductStore/REMOVE_USER_INFO',
    }),
    editAddress() {
      if (!this.PDPA || this.PDPA === null) {
        this.removeUserInfo(
          this.AddressShipping(`sp_${this.products.spTimestamp}`),
        )
      }

      location.href = `/${this.productCoach.route_name}?spTimestamp=${this.products.spTimestamp}`
    },
  },
}
</script>

<style scoped>
.title-order-address {
  font-size: 26px;
}

.subtitle-order-address {
  font-size: 20px;
  color: #6d6d6d;
}

.order-editor-address {
  font-size: 26px;
  color: #da1f28;
  cursor: pointer;
}

.title {
  color: #000;
}

@media screen and (max-width: 480px) {
  .title-order-address {
    font-size: 15px;
  }

  .subtitle-order-address {
    font-size: 13px;
  }

  .order-editor-address {
    font-size: 15px;
  }
}
</style>
