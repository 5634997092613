<template>
  <div class="redeem-location-main-page">
    <redeem-location-header></redeem-location-header>
    <main>
      <location-redeem
        v-if="redeemLocation"
        :redeemLocation="redeemLocation"
      ></location-redeem>
    </main>
  </div>
</template>

<script>
import moomallServices from '../services/moomallServices'

export default {
  name: 'RedeemLocationMainPage',
  data() {
    return {
      redeemLocation: null,
    }
  },
  async created() {
    const supplierId = this.$route.query['supplierId']
    const { data } = await moomallServices.get('location/' + supplierId)
    this.redeemLocation = data.data
  },
}
</script>

<style scoped>
.redeem-location-main-page {
  background: #f8f6f6;
}
</style>
