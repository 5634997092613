<template>
  <div
    id="CreateQrCodeMainPage"
    v-if="this.ProductDetail(`sp_${this.productPaymentDetail.spTimestamp}`)"
  >
    <qrcode-header></qrcode-header>
    <main>
      <div id="qr-code-main" class="container py-2 mb-1 bg-white">
        <qrcode-description
          v-if="qrcodeImageData"
          :qrCodeImageData="qrcodeImageData"
          :productPaymentDetail="productPaymentDetail"
        >
        </qrcode-description>
      </div>
    </main>
  </div>
</template>

<script>
import moomallServices from '../services/moomallServices'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CreateQrCodeMainPage',
  data() {
    return {
      checkStatus: 0,
      checkStatusInterval: null,
      qrcodeImageData: null,
      productCoach: null,
      paymentSuccessUrl: null,
      productPaymentDetail: null,
    }
  },
  computed: {
    ...mapGetters({
      OrderSummary: 'ProductStore/OrderSummary',
      ProductDetail: 'ProductStore/ProductDetail',
      AddressShipping: 'ProductStore/AddressShipping',
      QrCodeData: 'ProductStore/QrCodeData',
    }),
  },
  async created() {
    const routeName = this.$route.params['salepageName']
    const input = this.$route.query

    this.productPaymentDetail = input
    this.paymentSuccessUrl = `/${routeName}/payment_success`

    const productCoachResp = await moomallServices.get(
      `product_coach/name/${routeName}`,
    )
    const { productCoach } = productCoachResp.data
    this.productCoach = productCoach
    let generateQRResult

    try {
      const { data } = await moomallServices.post('generateQR', {
        id: this.productPaymentDetail.id,
        type: this.productPaymentDetail.type,
        orderSn: this.productPaymentDetail.orderSn,
        payMoney: this.productPaymentDetail.payMoney,
        mobile: this.productPaymentDetail.mobile,
      })
      generateQRResult = data
    } catch (err) {
      console.log('err QR', err)
      // For by pass QR Error in uat env
      if (process.env.VUE_APP_BY_PASS_QR == 'true') {
        console.log('by pass QR enabled')
        this.checkPaymentStatus()
      }
    }
    this.qrcodeImageData = generateQRResult.data

    if (
      this._.isEmpty(
        this.ProductDetail(`sp_${this.productPaymentDetail.spTimestamp}`) &&
          this.OrderSummary(`sp_${this.productPaymentDetail.spTimestamp}`),
      ) === false
    ) {
      console.log('SET QR CODE')
      this.setQrCodeData({
        data: this.qrcodeImageData,
        id: `sp_${this.productPaymentDetail.spTimestamp}`,
      })
    }

    if (
      this._.isEmpty(
        this.ProductDetail(`sp_${this.productPaymentDetail.spTimestamp}`) &&
          this.OrderSummary(`sp_${this.productPaymentDetail.spTimestamp}`) &&
          this.AddressShipping(`sp_${this.productPaymentDetail.spTimestamp}`) &&
          this.QrCodeData(`sp_${this.productPaymentDetail.spTimestamp}`),
      )
    ) {
      return (location.href = `/${this.productCoach.route_name}`)
    }

    // GTAG
    this.$gtag.pageview({
      page_title: this.productCoach.welcome_title + '- QRCode',
      page_location: window.location.href,
      page_path: this.productCoach.route_name,
    })

    this.checkPaymentStatus()
  },
  destroyed() {
    clearInterval(this.checkStatusInterval)
  },
  methods: {
    ...mapMutations({
      setQrCodeData: 'ProductStore/SET_QRCODE_DATA',
      removeOrderPaid: 'ProductStore/REMOVE_ORDER_PAID',
    }),
    async checkPaymentStatus() {
      this.checkStatusInterval = setInterval(async () => {
        const response = await moomallServices.checkPaymentStatus(
          this.OrderSummary(`sp_${this.productPaymentDetail.spTimestamp}`)
            .orderId,
        )
        const { paymentStatus } = response.data.data
        this.checkStatus = paymentStatus

        if (paymentStatus === 1) {
          this.$gtag.purchase({
            transaction_id: this.OrderSummary(
              `sp_${this.productPaymentDetail.spTimestamp}`,
            ).orderSn,
            affiliation: 'SalePage',
            value: this.OrderSummary(
              `sp_${this.productPaymentDetail.spTimestamp}`,
            ).totalOrderPrice,
          })
          clearInterval(this.checkStatusInterval)

          const query = this.prepareDataPaymentSuccess()
          this.$router.push({
            path: this.paymentSuccessUrl,
            query: { ...query },
          })

          await this.removeOrderPaid(
            `sp_${this.productPaymentDetail.spTimestamp}`,
          )
        }
      }, 5000)
    },
    prepareDataPaymentSuccess() {
      const orderSummary = this.OrderSummary(
        `sp_${this.productPaymentDetail.spTimestamp}`,
      )
      const address = this.AddressShipping(
        `sp_${this.productPaymentDetail.spTimestamp}`,
      )
      return {
        orderId: orderSummary.id,
        email: address.email,
        name: address.name,
        mobile: address.tel,
        zipcode: address.zipcode,
        spTimestamp: this.productPaymentDetail.spTimestamp,
        totalFreight: orderSummary.totalFreight,
        oldMonney: this.ProductDetail(
          `sp_${this.productPaymentDetail.spTimestamp}`,
        ).totalPrice,
      }
    },
  },
}
</script>

<style scoped></style>
