import { render, staticRenderFns } from "./ProductForm.vue?vue&type=template&id=99b68ade&scoped=true&"
import script from "./ProductForm.vue?vue&type=script&lang=js&"
export * from "./ProductForm.vue?vue&type=script&lang=js&"
import style0 from "./ProductForm.vue?vue&type=style&index=0&id=99b68ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99b68ade",
  null
  
)

export default component.exports