<template>
  <div id="PaymentSuccessMainPage">
    <payment-success-header></payment-success-header>
    <main>
      <div
        v-if="paymentSuccess"
        id="payment-ordersn"
        class="container py-2 mb-1 bg-white"
      >
        <payment-ordersn
          :orderDetail="paymentSuccess.data"
          :productCoach="productCoach"
          :products="products"
        >
        </payment-ordersn>
      </div>

      <div id="payment-description" class="container py-2 bg-white">
        <payment-description
          v-if="productCoach && productCoach.type === 1"
        ></payment-description>
        <evoucher-shop-detail
          v-else-if="productCoach && productCoach.type === 2 && paymentSuccess"
          :evoucherDetail="voucherDetail"
          :products="products"
        >
        </evoucher-shop-detail>
      </div>
    </main>
  </div>
</template>

<script>
import moomallService from '../services/moomallServices'

export default {
  name: 'PaymentSuccessMainPage',
  data() {
    return {
      productCoach: null,
      paymentSuccess: null,
      products: null,
      voucherDetail: null,
    }
  },
  methods: {
    map() {
      if (this.productCoach.type === 1) {
        location.href = `https://m.moomall.com/#/pages/product/product?id=${this.productCoach.product_id}`
      } else {
        location.href = `https://m.moomall.com/#/pages/e-vouchers/product-detail?id=${this.productCoach.product_id}`
      }
    },
  },
  async created() {
    const routeName = this.$route.params['salepageName']
    const productCoachRes = await moomallService.get(
      `/product_coach/name/${routeName}`,
    )
    const { productCoach, product } = productCoachRes.data
    this.productCoach = productCoach

    this.voucherDetail = product?.data?.voucherDetail

    const products = this.$route.query
    this.products = products

    const { data } = await moomallService.post(
      `/payment/success/${routeName}`,
      {
        ...products,
      },
    )
    this.paymentSuccess = data.paymentSuccess

    // GTAG
    this.$gtag.pageview({
      page_title: this.productCoach.welcome_title + '- success',
      page_location: window.location.href,
      page_path: this.productCoach.route_name,
    })
  },
}
</script>

<style scoped></style>
