<template>
  <div id="OtpInput">
    <vue-otp-input
      ref="otpInput"
      input-classes="otp-input"
      separator=""
      :num-inputs="6"
      input-type="number"
      :should-auto-focus="true"
      :is-input-num="true"
      @on-change="handleOnChange"
      @on-complete="handleOnComplete"
      v-model.trim="$v.otp.$model"
      :class="{ 'is-invalid': $v.otp.$error, 'is-valid': !$v.otp.$invalid }"
    />
    <div
      class="invalid-feedback text-center"
      v-if="!$v.otp.required || !$v.otp.numeric || !$v.otp.minLength"
    >
      โปรดกรอก OTP ให้ถูกต้อง
    </div>
    <div class="otp-text text-center mt-1" v-if="!checkOtp">
      คุณกรอกรหัส OTP ผิด
    </div>

    <salepage-popup
      @toggleModal="toggleModal"
      :showModal="showModal"
      :productCoach="productCoach"
    />
  </div>
</template>

<script>
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import moomallServices from '../../services/moomallServices'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OtpInput',
  props: {
    qrCodeUrl: {
      type: String,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
    tel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      otp: '',
      checkOtp: true,
      showModal: false,
    }
  },
  validations: {
    otp: {
      required,
      numeric,
      minLength: minLength(6),
    },
  },
  computed: {
    ...mapGetters({
      ProductDetail: 'ProductStore/ProductDetail',
      PDPA: 'ProductStore/PDPA',
      AddressShipping: 'ProductStore/AddressShipping',
      OrderSummary: 'ProductStore/OrderSummary',
    }),
  },
  methods: {
    ...mapMutations({
      removeUserInfo: 'ProductStore/REMOVE_USER_INFO',
    }),
    toggleModal() {
      this.showModal = !this.showModal
    },
    handleOnComplete(value) {
      this.checkOtp = true
      this.otp = value
    },
    handleOnChange(value) {
      this.checkOtp = true
      this.otp = value
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
      this.checkOtp = true
      this.otp = ''
    },
    async sendOtp() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        await moomallServices
          .postChenkOtp({
            tel: this.tel,
            otp: this.otp,
            invite: this.productCoach.invite_code,
            sn: this.OrderSummary(`sp_${this.products.spTimestamp}`).sn,
          })
          .then(resp => {
            if (resp.status === 200) {
              if (resp.data.status === true) {
                if (resp.data.data === false) {
                  return this.toggleModal()
                }

                this.generateQR()
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.checkOtp = false
          })
      }
    },
    async generateQR() {
      if (!this.PDPA || this.PDPA === null) {
        this.removeUserInfo()
      }

      this.$router.push({
        path: this.qrCodeUrl,
        query: { ...this.products },
      })
    },
  },
}
</script>

<style lang="less">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  color: #da1f28;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-text {
  color: red;
  font-size: 12px;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
