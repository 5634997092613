<template>
  <div class="text-center mb-3" v-if="videoPath">
    <video autoplay muted controls :poster="posterPath" :class="css">
      <source type="video/mp4" :src="path" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'ProductVideo',
  props: {
    videoPath: {
      type: [String, Number],
      default: '',
    },
    fileUrl: {
      type: [String, Number],
      default: process.env.VUE_APP_MOOMALL_FILE_URL,
    },
    posterUrl: {
      type: [String],
      default: '',
    },
    css: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    path() {
      return this.fileUrl + this.videoPath
    },
    posterPath() {
      return this.fileUrl + this.posterUrl
    },
  },
}
</script>

<style scoped>
.product-video {
  max-width: 100% !important;
  height: 500px !important;
}

@media screen and (max-width: 480px) {
  .product-video {
    max-width: 100% !important;
    height: 350px !important;
  }
}
</style>
