<template>
  <div id="ProductSku">
    <div class="title-sku">ตัวเลือกสินค้า</div>
    <div class="card">
      <div class="sku-body">
        <div class="image-sku">
          <product-image :pathImage="selectGood.masterImg"></product-image>
        </div>
        <div class="detail-sku">
          <div class="merchant-brand" v-show="productCoach.type === 2">
            {{ productName }}
          </div>

          <div class="product-price" v-if="productCoach.type === 2">
            <span class="money main-price">
              {{ formatPrice(selectGood.memberPrice) || '0' }}</span
            >
            <span
              class="sticker-price money"
              v-if="
                parseFloat(selectGood.stickerPrice) >
                parseFloat(selectGood.memberPrice)
              "
            >
              {{ formatPrice(selectGood.stickerPrice) }}
            </span>
            <span
              class="discount"
              v-if="
                selectGood.discountPercentage != null &&
                selectGood.discountPercentage > 0
              "
            >
              -{{ selectGood.discountPercentage }}%
            </span>
          </div>

          <div class="product-price" v-else>
            <span class="money main-price">
              {{
                (selectGood.isFlash > 0
                  ? formatPrice(selectGood.flashPrice)
                  : formatPrice(selectGood.memberPrice)) ||
                formatPrice(productDetail.skuMinPrice)
              }}
            </span>
            <span
              class="sticker-price money"
              v-if="
                selectGood.isFlash == 0 &&
                parseFloat(selectGood.stickerPrice) >
                  parseFloat(selectGood.memberPrice)
              "
            >
              {{ formatPrice(selectGood.stickerPrice) }}
            </span>
            <span
              class="discount"
              v-if="
                selectGood.isFlash == 0 &&
                selectGood.discountPercentage != null &&
                selectGood.discountPercentage > 0
              "
            >
              -{{ selectGood.discountPercentage }}%
            </span>
          </div>

          <div class="product-quantity" v-show="productCoach.type === 1">
            มีสินค้า {{ selectStock }}
          </div>
          <div
            class="product-select"
            v-for="(sItem, sIndex) in specSelected"
            :key="sIndex"
          >
            <span class="select">เลือก</span> {{ sItem.name }}
          </div>
        </div>
      </div>
    </div>

    <v-divider class="mx-1 my-3"></v-divider>

    <div class="product-select-type">
      <div
        v-for="(item, index) in this.productSku.normList"
        :key="index"
        class="attr-list"
      >
        <div class="title-spec">{{ item.name }}</div>
        <div class="item-list">
          <div
            class="box-select"
            v-for="(childItem, childIndex) in item.attrList"
            :key="childIndex"
            :class="[{ selected: childItem.selected == 1 }]"
            @click="selectSpec(childIndex, item.name)"
          >
            {{ childItem.name }}
          </div>
        </div>
      </div>

      <v-divider class="mx-1 my-2"></v-divider>

      <div class="d-flex justify-content-between">
        <div class="title-type-quantity my-auto">จำนวน</div>
        <div class="my-auto">
          <number-input
            class="number-input"
            v-model="selectNum"
            size="small"
            inline
            center
            controls
            :inputtable="false"
            :disabled="previewId == 0"
            @change="createOrder"
            :min="selectStock <= 0 ? 0 : 1"
            :max="selectStock <= 0 ? 0 : selectStock"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as numeral from 'numeral'

export default {
  name: 'ProductSku',
  props: {
    productSku: {
      type: Object,
      required: true,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    productDetail: {
      type: Object,
    },
    productName: {
      type: String,
    },
  },
  data() {
    return {
      specSelected: [],
      selectGood: {},
      selectStock: 1,
      selectNum: 1,
      isBackToEdit: false,
    }
  },
  computed: {
    ...mapGetters({
      ProductDetail: 'ProductStore/ProductDetail',
    }),
  },
  mounted() {
    if (this.$route.query.spTimestamp) {
      this.isBackToEdit = true
      const localProductDetail = this.ProductDetail(
        `sp_${this.$route.query.spTimestamp}`,
      )
      this.specSelected.push(...localProductDetail.specSelected)
      this.productSku.normList.map((norm, index) => {
        const attrIndex =
          norm.attrList.length === 1
            ? 0
            : norm.attrList.findIndex(
                attr => attr.id === this.specSelected[index].id,
              )
        this.productSku.normList[index].attrList[attrIndex].selected = 1
      })
      this.selectNum = localProductDetail.selectNumTotal
    } else {
      this.productSku.normList.map((item, index) => {
        this.specSelected.push(item.attrList[0])
        this.productSku.normList[index].attrList[0].selected = 1
      })
    }
    this.changeSelectGoods()
    this.isBackToEdit = false
  },
  methods: {
    createOrder() {
      this.selectGood = {
        ...this.selectGood,
        ...{
          selectNumTotal: this.selectNum,
          specSelected: this.specSelected,
          totalPrice: this.selectGood.price * this.selectNum,
        },
      }
      this.$emit('updateOrder', this.selectGood)
    },
    selectSpec(num, pName) {
      if (this.previewId !== 0) {
        this.productSku.normList.map((item, index) => {
          if (item.name == pName) {
            item.attrList.map((_, sIndex) => {
              this.productSku.normList[index].attrList[sIndex].selected = 0
            })
            this.productSku.normList[index].attrList[num].selected = 1
          }
        })
        this.specSelected = []
        this.productSku.normList.map(item => {
          item.attrList.map(obj => {
            if (obj.selected == 1) {
              this.specSelected.push(obj)
            }
          })
        })
        this.changeSelectGoods()
      }
    },
    changeSelectGoods() {
      if (this.productSku.goods.length == 1) {
        this.selectGood = this.productSku.goods[0]
      } else {
        let temArr = []
        this.specSelected.map(item => {
          temArr.push(item.id)
        })
        this.productSku.goods.map(item => {
          if (temArr.sort().toString() == item.normAttrId.sort().toString()) {
            this.selectGood = item
          }
        })
      }
      this.selectStock =
        this.selectGood.isFlash > 0
          ? this.selectGood.quantity - this.selectGood.usedQuantity
          : this.selectGood.productStock
      this.selectStock = this.selectStock <= 0 ? 0 : this.selectStock
      // NOTE: Normally when sku is change we should set selectNum = 1
      // But when we back to edit address we need to keep value that has been set from mounted() e.g. selectNum = 3
      if (!this.isBackToEdit) {
        this.selectNum = this.selectStock > 0 ? 1 : 0
        this.isBackToEdit = false
      }
      this.createOrder()
    },
    formatPrice(price) {
      return numeral(price).format('0,0')
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: 0px;
  border-radius: 0px;

  .sku-body {
    display: flex;
    margin-top: 10px;

    .image-sku {
      min-width: 80px;
      min-height: 80px;
      width: 80px;
      height: 80px;

      margin-right: 10px;
    }

    .detail-sku {
      // .money {}

      .merchant-brand {
        font-size: 20px;

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }
      }

      .main-price {
        font-size: 40px;
        font-weight: bold;
        color: #da1f28;

        @media screen and (max-width: 480px) {
          font-size: 18px;
        }
      }

      .sticker-price {
        color: #aaa;
        text-decoration: line-through;
        padding-left: 10px;

        font-size: 25px;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }

      .discount {
        font-size: 20px;
        padding-left: 5px;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }

      .money:before {
        font-size: 18px;
        content: '฿';
        margin-right: -3px;

        @media screen and (max-width: 480px) {
          font-size: 8px;
        }
      }

      .product-quantity {
        font-size: 20px;
        color: #bebebe;

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }
      }

      .product-select {
        font-size: 20px;

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }

        .select {
          color: #bebebe;
        }
      }
    }
  }
}

.product-select-type {
  .attr-list {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }

  .item-list {
    padding: 10px 0 0;
    display: flex;
    flex-wrap: wrap;

    .box-select {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eaeaea;
      margin-right: 10px;
      margin-bottom: 10px;
      height: auto;
      padding: 0 10px;
      color: #453d3d;
      border-radius: 14px;
      min-width: 200px;
      font-size: 20px;

      @media screen and (max-width: 480px) {
        min-width: 90px;
        font-size: 14px;
      }
    }

    .selected {
      background: #fbeaee;
      color: #da1f28;
    }
  }

  .number-input {
    border: 0px solid #212121;
    width: 120px;
  }
}

.selector {
  color: #212121;
  border-color: #212121;
}

.title-sku {
  color: #999999;
  font-size: 20px;

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
}

.title-type-quantity {
  font-size: 30px;
}

@media screen and (max-width: 480px) {
  .title-type-quantity {
    font-size: 13px;
  }
}
</style>
