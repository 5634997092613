<template>
  <div id="redeemLocations">
    <div class="evoucher-redeem">
      <div class="d-flex justify-content-between mb-2">
        <div class="location-redeem">
          สถานที่ใช้คูปอง ({{ evoucherDetail.merchantCount }})
        </div>
        <div class="see-all" @click="seeAll()" style="cursor: pointer">
          ดูสถานที่ทั้งหมด
          <img
            src="@/assets/icon-next-red.svg"
            class="img-fluid ml-1"
            width="6"
            height="11"
            alt="Icon Next"
          />
        </div>
      </div>
    </div>
    <location
      class="py-2"
      :img="evoucherDetail.merchantImage"
      :name="evoucherDetail.merchantName"
      :address="evoucherDetail.merchantAddress"
      :phone="evoucherDetail.merchantMobile"
      :border="false"
      :img-col="3"
      :address-col="9"
    />
    <form ref="form" action="/redeem_location">
      <input
        type="hidden"
        name="supplierId"
        v-model="this.evoucherDetail.supplierId"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: 'redeemLocations',
  props: {
    evoucherDetail: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async seeAll() {
      await this.$refs.form.submit()
    },
  },
}
</script>

<style lang="scss" scoped>
.evoucher-redeem {
  border-bottom: 0.5px solid #eaeaea;
  max-height: 148px;
}

.location-redeem {
  font-size: 22px;
  color: #453d3d;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.see-all {
  color: #da3d3c;
  font-size: 22px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}
</style>
