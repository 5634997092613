import axios from 'axios'

export const apiServices = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  mode: 'cors',
  baseURL: process.env.VUE_APP_BASE_API_URL || 'http://localhost:8888',
})

export const apiDownload = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  mode: 'cors',
  responseType: 'blob',
})

export default {
  getProvice() {
    return apiServices.get(`/address/province`)
  },

  getDistrict(id) {
    return apiServices.get(`/address/district/${id}`)
  },

  getSub_district(id) {
    return apiServices.get(`/address/sub_district/${id}`)
  },

  getSendOtp(tel) {
    return apiServices.get(`/otp/send/${tel}`)
  },

  postChenkOtp({ tel, otp, invite, sn }) {
    return apiServices.post('/otp/check', {
      mobile: tel,
      otp: otp,
      invite_code: invite,
      sn,
    })
  },

  downloadImage(urlImage) {
    return apiDownload.get(urlImage)
  },

  checkPaymentStatus(orderOrSummaryId) {
    return apiServices.post(`/payment/checkPaymentStatus/${orderOrSummaryId}`)
  },

  getCommentList({ productId, pageIndex, pageSize }) {
    return apiServices.post('/comment_list', {
      productId,
      pageIndex,
      pageSize,
    })
  },
  get(url, params) {
    return apiServices.get(url, {
      params,
    })
  },
  post(url, body) {
    return apiServices.post(url, {
      ...body,
    })
  },
}
