<template>
  <v-snackbar
    v-model="snackbar"
    text
    :dark="!whiteMode"
    :light="whiteMode"
    bottom
    centered
    :timeout="showTimeout"
    min-width="unset"
  >
    <div
      class="custom-snackbar"
      :class="{ 'custom-snackbar--white': whiteMode }"
    >
      <div class="mb-2" v-if="icon">
        <v-icon
          :size="iconSize"
          :color="iconColor"
          class="custom-snackbar__icon"
          >{{ icon }}</v-icon
        >
      </div>
      <div>
        <span class="custom-snackbar__text">{{ message }}</span>
      </div>
      <div v-if="showBtn" class="custom-snackbar__btn">
        <v-btn :color="btnColor" text @click="clickBtn">
          {{ btnText }}
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SnackBar',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: 18,
    },
    iconColor: {
      type: String,
      default: '',
    },
    whiteMode: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: 'OK',
    },
    btnColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      snackbar: false,
      timeout: 2 * 1000,
    }
  },
  computed: {
    showTimeout() {
      // If show btn = true; -1 is mean no timeout, otherwise is default
      return this.showBtn ? -1 : this.timeout
    },
  },
  watch: {
    value(val) {
      this.snackbar = val
    },
    snackbar(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    clickBtn() {
      this.snackbar = false
      this.$emit('click')
    },
  },
})
</script>

<style lang="scss" scoped>
::v-deep .v-sheet.v-snack__wrapper {
  border-radius: 14px !important;
  opacity: 0.8;
}

::v-deep .v-snack__content {
  padding: 6px 12px;
}

::v-deep .v-snack__wrapper.v-sheet.theme--light .v-snack__content {
  border-radius: inherit;
  background-color: #ffffff;
}

::v-deep .v-btn__content {
  font-weight: bold;
  font-size: 16px;
}

.custom-snackbar {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 16px;
  color: white;

  &--white {
    color: black;
    background-color: #ffffff;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }

  &__btn {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    border-top: solid 1px #fcefef;
  }
}
</style>
