<template>
  <div id="evoucherQrCode">
    <div class="qrcode-title text-grey text-center mb-2">
      กรุณายืนยันตัวตนเมื่อคุณอยู่ที่ร้านแลกคูปองหรือหน้าแคชเชียร์เท่านั้น
    </div>
    <div class="qrcode-title text-center mb-2">รหัสบัตรกำนัล</div>

    <div
      class="d-flex justify-content-between order-sn mb-3"
      v-for="item in this.evoucherDetail.resourceList"
      :key="item.id"
    >
      <img
        src="@/assets/voucher-code.png"
        class="iconQrcode img-fluid my-auto"
      />
      <div class="qrcode-title my-auto">
        คุณซื้อได้ในราคา <span class="text-price">฿{{ evoucherPrice }}</span>
      </div>
      <button
        type="button"
        class="btn btn-outline-danger qrcode"
        data-toggle="modal"
        :data-target="`#Modal${item.id}`"
        @click="showQrCode(item)"
      >
        แสดง
      </button>

      <div
        class="modal fade"
        :id="`Modal${item.id}`"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="qrcode-title text-center mb-2">รหัสบัตรกำนัล</div>
              <div class="d-flex justify-content-center mb-2">
                <vue-qrcode
                  v-if="value"
                  :value="value"
                  :width="size"
                  ref="qrcode"
                />
              </div>
              <div class="qrcode-title text-center mb-2">{{ value }}</div>
              <div class="d-flex justify-content-center mb-4">
                <barcode
                  :value="value"
                  :displayValue="false"
                  height="60"
                  :width="1"
                >
                  QR Code Rendering fails.
                </barcode>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-danger qrcode"
              >
                ตกลง
              </button>
              <button
                type="button"
                class="btn btn-outline-danger qrcode"
                @click="download(value)"
              >
                บันทึก QR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        <div class="subtitle text-center mb-4" v-if="evoucherDetail != null && evoucherDetail.expireMethod == 2">-->
    <!--            คูปองนี้จะมีอายุ {{ evoucherDetail.validDay }} วันหลังจากที่ซื้อ-->
    <!--        </div>-->
    <div class="subtitle text-center mb-4">
      กรุณาใช้ก่อน {{ evoucherDetail.redeemEndTime.substring(0, 10) }}
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import VueQrcode from 'vue-qrcode'

export default {
  name: 'evoucherQrCode',
  props: {
    evoucherDetail: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: '',
      size: 250,
    }
  },
  computed: {
    evoucherPrice() {
      const priceString =
        this.evoucherDetail.voucherPrice / this.evoucherDetail.number
      return parseFloat(priceString).toFixed(2)
    },
  },
  methods: {
    showQrCode(data) {
      this.value = data.code
    },
    download(value) {
      for (let item of this.$refs.qrcode) {
        if (item.value === value) {
          var a = document.createElement('a') //Create <a>
          a.href = item.dataUrl //Image Base64 Goes here
          a.download = `qrcode${item.value}.png` //File name Here
          a.click() //Downloaded file
          break
        }
      }
    },
  },
  components: {
    barcode: VueBarcode,
    VueQrcode,
  },
}
</script>

<style scoped>
.subtitle {
  font-size: 20px;
  color: #6d6d6d;
}

.qrcode-title {
  font-size: 20px;
}

.text-grey {
  color: #aaaaaa;
}

.text-price {
  color: #da2a24;
}

.qrcode {
  border-radius: 15px;
}

.iconQrcode {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 480px) {
  .qrcode-title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 13px;
  }
}
</style>
