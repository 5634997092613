<template>
  <div id="PaymentDescription">
    <div class="box-shipping mb-3">
      <div class="title">การจัดส่งสินค้า</div>
      <div class="subtitle">
        จัดส่งสินค้าภายในประเทศไทย หลังจากสั่งซื้อสินค้าสำเร็จ
        ทางบริษัทจะทำการจัดส่งสินค้าให้ท่านภายใน 5-7 วัน
        <ul>
          <li>กรุงเทพฯและปริมณฑล 2-3 วันทำการ</li>
          <li>ต่างจังหวัด 3-5 วันทำการ</li>
        </ul>
      </div>
    </div>

    <div class="box-warranty mb-3">
      <div class="title">การรับประกันสินค้า</div>
      <div class="subtitle">สามารถเปลี่ยนหรือคืนสินค้าได้ภายใน 7 วัน</div>
    </div>

    <div class="box-return-product mb-3">
      <div class="title">วิธีการเปลี่ยนคืนสินค้า</div>
      <div class="subtitle">
        หากต้องการเปลี่ยนคืนสินค้า
        สามารถติดต่อฝ่ายลูกค้าสัมพันธ์หลังจากได้รับสินค้าภายใน 7 วัน
        <ul>
          <li>
            โทร 02-114-6595 ต่อ 1
            <div>(จันทร์-อาทิตย์ 9.00-20.00 น.)</div>
          </li>
          <li>LineOfficial : @moomallofficial</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentDescription',
}
</script>

<style scoped>
.title {
  font-size: 26px;
}

.subtitle {
  font-size: 20px;
  color: #6d6d6d;
}

a.moomall {
  color: #761b18;
  text-decoration: underline !important;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 13px;
  }
}
</style>
