var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ProductName"}},[(
      _vm.propductName.isFlash == 0 &&
      _vm.propductName.skuMinPrice != _vm.propductName.skuMaxPrice
    )?_c('div',{staticClass:"product-price mb-3"},[_vm._v(" ฿"+_vm._s(_vm.propductName.skuMinPrice)+"~"+_vm._s(_vm.propductName.skuMaxPrice)+" ")]):_vm._e(),(
      _vm.propductName.isFlash == 0 &&
      _vm.propductName.skuMinPrice == _vm.propductName.skuMaxPrice
    )?_c('div',{staticClass:"product-price mb-3"},[_vm._v(" ฿"+_vm._s(_vm.propductName.skuMinPrice)+" ")]):_vm._e(),_c('div',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.propductName.name1))]),_c('div',{staticClass:"subtitle-product-name"},[_vm._v(_vm._s(_vm.propductName.name2))]),(
      _vm.propductName.freeFreightAmount == null ||
      _vm.propductName.freeFreightAmount == 0
    )?_c('div',{staticClass:"product-shipping"},[_vm._v(" จัดส่งฟรี ")]):_vm._e(),(_vm.propductName.freeFreightAmount > 0)?_c('div',{staticClass:"product-shipping"},[_vm._v(" จัดส่งฟรีเมื่อซื้อเกิน "+_vm._s(_vm.propductName.freeFreightAmount)+" บาท ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }