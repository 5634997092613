<template>
  <div class="page">
    <main class="page-main">
      <product-header>{{ productCoach.welcome_title }}</product-header>

      <div class="page-main-detail">
        <product-image
          class="page-main-detail-img"
          :pathImage="welcomeImage.id"
          urlImage="file/"
          v-if="welcomeImage"
        >
        </product-image>
        <product-image-carousel
          class="page-main-detail-carousel"
          :productImageSlide="productDetail.productPic"
        >
        </product-image-carousel>

        <product-message
          class="product-message"
          :message="productCoach.welcome_message"
        ></product-message>
      </div>

      <product-description
        :productDescription="productDetail.description"
      ></product-description>

      <product-delivery-description
        :productDelivery="productDetail"
        :specInfo="specInfo"
        :productName="productCoach.welcome_title"
      />

      <div
        id="product-review"
        class="container px-0 py-0 page-main-product-review"
        v-if="
          productDetail.commentsNumber > 0 &&
          productDetail.commentsList != null &&
          productDetail.commentsList.length != 0
        "
      >
        <product-review
          :productReview="productDetail.commentsList"
          :productDetail="productDetail"
        ></product-review>
      </div>

      <div class="product-sku">
        <div class="title">สั่งซื้อตอนนี้พิเศษเพียง</div>
        <div class="container bg-products bg-sku">
          <product-sku
            :productSku="this.productGoods"
            :productDetail="productDetail"
            :productCoach="productCoach"
            @updateOrder="updateOrder"
            :previewId="parseInt(previewId)"
          >
          </product-sku>
        </div>
      </div>

      <product-order
        class="container py-2 mb-2 bg-products product-order"
        :productOrder="this.productOrder"
      />

      <product-form
        class="container mb-2 bg-products"
        :createOrderUrl="createOrderUrl"
        :product="productOrder"
        :previewId="parseInt(previewId)"
        :productCoach="productCoach"
        :state="this.product.data.productState"
        :offshelf="productCoach.offshelf"
      >
      </product-form>
    </main>

    <div
      class="alert alert-dark alert-dismissible fade show fixed-bottom"
      role="alert"
      v-show="PDPA === null && parseInt(previewId) !== 0"
    >
      <div class="mb-2">
        <span class="text-center">
          เว็บไซต์ของเรามีการเก็บ cookies
          ซึ่งเก็บข้อมูลว่าคุณใช้งานเว็บไซต์ของเราอย่างไรและช่วยให้เราจดจำคุณได้
          เราใช้ข้อมูลนี้เพื่อการทำ analytics
          และนำมาสู่การทำให้ประสบการณ์การใช้เว็บไซต์ดียิ่งขึ้น
        </span>
      </div>
      <div class="alert-btn d-flex justify-content-end">
        <button
          @click="NotConfirmPDPA()"
          type="button"
          class="btn alert-btn-no mr-3"
        >
          ปฏิเสธ
        </button>
        <button
          @click="ConfirmPDPA()"
          type="button"
          class="btn alert-btn-confirm"
        >
          ยินยอม
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moomallService from '@/services/moomallServices'

export default {
  name: 'ProductsMainPage',
  props: {
    product: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    welcomeImage: {
      type: Object,
    },
    reviewImages: {
      type: Array,
    },
    createOrderUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productDetail: Object,
      productGoods: Object,
      specInfo: '',
      productOrder: {},
      reviewProfileImage: null,
    }
  },
  computed: {
    ...mapGetters({ PDPA: 'ProductStore/PDPA' }),
  },
  async created() {
    this.productDetail = this._.get(this.product.data, 'productDetail')
    this.productGoods = this._.get(this.product.data, 'productGoods')

    if (this.productDetail.productVideo) {
      this.productDetail.productPic = [
        {
          type: 'video',
          videoPath: this.productDetail.productVideo,
        },
        ...this.productDetail.productPic,
      ]
    }

    // Set type to carousel for image only
    this.productDetail.productPic.map(p => {
      if (p.type !== 'video') {
        p.type = 'main-carousel'
      }
      return p
    })

    this.productGoods.normList.map((item, index) => {
      if (index < 1) {
        item.attrList.map(spec => {
          this.specInfo += spec.name + ';'
        })
      }
    })

    this.productGoods.goods.map((item, index) => {
      this.productGoods.goods[index].normAttrId = item.normAttrId.split(',')
    })

    try {
      const url = `/member/${this.productCoach.invite_code}`
      const res = await moomallService.get(url)
      if (res.status === 200) {
        const member = res.data.data
        this.reviewProfileImage = member.headImg
      }
    } catch (error) {
      console.error(error)
    }

    // GTAG
    this.$gtag.pageview({
      page_title: this.productCoach.welcome_title + '- main',
      page_location: window.location.href,
      page_path: this.productCoach.route_name,
      product_coach_id: this.productCoach.id,
    })
  },
  methods: {
    ...mapMutations({ getComfirmPDPA: 'ProductStore/SET_COMFIRM_PDPA' }),
    updateOrder(order) {
      console.log('updateORder:', order)
      this.productOrder = order
      this.productOrder = {
        ...this.productOrder,
        ...{
          companyName: this.productDetail.companyName,
          name1: this.productDetail.name1,
        },
      }
    },
    ConfirmPDPA() {
      this.getComfirmPDPA(true)
    },
    NotConfirmPDPA() {
      this.getComfirmPDPA(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  &-main {
    &-detail {
      margin-top: 82px;

      &-img {
        min-width: 375px;
        min-height: 375px;
      }

      &-carousel {
        min-width: 375px;
        min-height: 375px;
      }
    }

    &-product-review {
      margin-top: 22px;
    }

    .product-sku {
      .title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;

        margin-bottom: 8px;
        margin-top: 22px;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }

      .bg-sku {
        border-radius: 10px;
      }
    }

    .product-order {
      margin-top: 10px;
    }
  }
}

.product-header {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 30px;
  z-index: 99;
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
}

.product-message {
  margin-top: 16px;
  margin-bottom: 15px;
}

.bg-products {
  background-color: #ffffff;
}

// above is new css

.bg-products {
  background-color: #ffffff;
}

.border-bottom {
  border-bottom: 20px solid #f8f8f8 !important;
}

.alert {
  margin-bottom: 0px;
  padding: 1rem 1rem 1.5rem 1rem;
  color: #453d3d;
  font-size: 13px;
  z-index: 99999999999;

  &-btn {
    &-no {
      color: #dbdbdb;
      border: 1px solid #eaeaea;
      font-size: 16px;
      border-radius: 14px;
    }

    &-confirm {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 14px;
      background-color: #da3d3c;
    }
  }

  &-dark {
    background-color: #fff;
  }
}

@media screen and (max-width: 480px) {
  .border-bottom {
    border-bottom: 10px solid #f8f8f8 !important;
  }
}
</style>
