<template>
  <nav class="navbar navbar-expand-lg" style="height: 50px">
    <div class="container-fluid">
      <button class="btn rounded-0 btn-sm" @click="redirect">
        <img
          src="@/assets/icon-back-red.svg"
          class="img-fluid thai-qr-image"
          width="6"
          height="11"
          alt="Icon Back"
        />
      </button>
      <div class="mx-auto">
        <div class="title-header">สถานที่ใช้คูปองทั้งหมด</div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'RedeemLocationHeader',
  methods: {
    redirect() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.title-header {
  font-size: 24px;
  color: #da3d3c;
}

@media screen and (max-width: 480px) {
  .title-header {
    font-size: 16px;
  }
}
</style>
