<template>
  <div>
    <img :src="pathImg" alt="Avatar" class="avatar" />
    <slot name="name-avatar">Name User</slot>
  </div>
</template>

<script>
export default {
  name: 'ProductImageAvatar',
  props: {
    pathImage: {
      type: [String],
    },
  },
  computed: {
    pathImg() {
      return this.pathImage ? this.pathImage : require('@/assets/avatar.png')
    },
  },
}
</script>

<style scoped>
.avatar {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

@media screen and (max-width: 480px) {
  .avatar {
    width: 24px;
    height: 24px;
  }
}
</style>
