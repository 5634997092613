<template>
  <div class="product-header">
    <div class="title-header cut-text-multi">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductHeader',
}
</script>

<style lang="scss" scoped>
.cut-text-multi {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-header {
  min-width: 343px;
  min-height: 62px;

  background-color: white;
  border-radius: 14px 14px 14px 14px;
  box-shadow: 0px 3px 6px 0px #0000001a;

  .title-header {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

    font-size: 16px;
    font-weight: bold;
  }
}
</style>
