<template>
  <div id="ProductReview">
    <div class="custom-title">รีวิวจากผู้ซื้อ</div>
    <div class="card" v-for="item in commentList" :key="item.id">
      <div class="custom-card-title">
        <product-image-avatar :pathImage="item.headImg || '/img/avatar.png'">
          <template v-slot:name-avatar>
            <div class="custom-review-name d-inline">
              {{ item.memberAccount }}
            </div>
          </template>
        </product-image-avatar>
        <product-review-rating :ratingStar="item.grade"></product-review-rating>
      </div>

      <div class="custom-card-body">
        <p class="card-text">
          {{ item.content }}
        </p>
        <product-image-carousel
          v-if="item.imgList"
          :productCommentList="item.imgList"
        >
        </product-image-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import moomallServices from '../../services/moomallServices'

export default {
  name: 'ProductReview',
  props: {
    productReview: {
      type: Array,
      default: () => [],
    },
    productDetail: {
      type: Object,
    },
  },
  data() {
    return {
      commentList: [],
    }
  },
  methods: {
    async getCommentList() {
      const resp = await moomallServices.getCommentList({
        productId: this.productDetail.id,
        pageIndex: process.env.VUE_APP_PAGE_INDEX_REVIEW,
        pageSize: process.env.VUE_APP_PAGE_SIZE_REVIEW,
      })
      this.commentList = resp.data.data.commentList.rows || []

      this.commentList.map(item => {
        if (item.imgPath) {
          item.imgList = { img: item.imgPath.split(',') }
        }
      })
    },
  },
  mounted() {
    this.getCommentList()
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: 0px;
  border-radius: 14px;
  margin-bottom: 10px;

  padding: 10px 16px;
}

.custom {
  &-title {
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &-card {
    &-title {
      display: flex;
    }

    &-body {
      font-size: 14px;
      margin-top: 9px;
    }
  }

  &-review-name {
    font-size: 30px;
  }
}

.card-text {
  margin-bottom: 8px;
}

@media screen and (max-width: 480px) {
  .custom {
    &-review-name {
      font-size: 12px;
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  .card-text {
    font-size: 14px;
  }
}
</style>
