<template>
  <div id="OtpDescription">
    <div class="otp-desc-form text-center container mb-3">
      <img
        src="@/assets/otp-icon.png"
        class="img-fluid thai-qr-image"
        width="98"
        alt="OTP Icon"
      />
      <div class="title-otp">โปรดกรอกรหัส OTP</div>
      <div class="form-group">
        <div class="text-center">
          <div class="mb-2 subtitle-otp">หมายเลขโทรศัพท์สำหรับรับรหัส OTP</div>
          <input
            class="form-control tel-input"
            type="tel"
            id="tel-input"
            v-model.trim="$v.tel.$model"
            :class="{
              'is-invalid': $v.tel.$error,
              'is-valid': !$v.tel.$invalid,
            }"
          />
          <div v-if="!$v.tel.required" class="invalid-feedback">
            กรุณาป้อนหมายเลขโทรศัพท์มือถือ
          </div>
          <div v-if="!$v.tel.isTelValid" class="invalid-feedback">
            กรุณากรอกหมายเลขโทรศัพท์มือถือให้ถูกต้อง
          </div>
        </div>
      </div>
    </div>

    <div class="input-otp mb-4 d-flex justify-content-center">
      <otp-input
        ref="OtpInput"
        :qrCodeUrl="qrCodeUrl"
        :productCoach="productCoach"
        :products="products"
        :tel="tel"
      >
      </otp-input>
    </div>

    <div class="otp-footer container text-center">
      <button
        type="button"
        class="btn btn-lg rounded-0 mb-2 otp-btn"
        :disabled="$v.tel.$invalid || isRequestOTP"
        :style="{
          backgroundColor: $v.tel.$invalid ? '#ededed' : '#da1f28',
          color: $v.tel.$invalid ? 'black' : 'white',
        }"
        @click="requestOTP"
      >
        ขอ OTP
      </button>
      <div
        class="box-otp d-flex justify-content-center mt-2"
        v-if="time_left > 0"
      >
        <div class="otp-required">
          <button type="button" class="btn btn-time-left">
            {{ time_left }}s
          </button>
        </div>
      </div>
    </div>
    <share-footer-button
      class="container"
      @click="sendOtp"
      :disabled="!isRequestOTP"
    ></share-footer-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moomallServices from '../../services/moomallServices'
import { required, helpers } from 'vuelidate/lib/validators'

export default {
  name: 'OtpDescription',
  props: {
    qrCodeUrl: {
      type: String,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      time_left: 0,
      tel: '',
      isRequestOTP: false,
      intervalOTPKey: null,
    }
  },
  computed: {
    ...mapGetters({
      AddressShipping: 'ProductStore/AddressShipping',
    }),
  },
  mounted() {
    this.$v.$touch()
    const timestamp = this.$route.query.spTimestamp
    this.tel = this.AddressShipping(`sp_${timestamp}`).tel
  },
  validations: {
    tel: {
      required,
      isTelValid: helpers.regex('isTelValid', /^[0](?:[0-9] ?){8}[0-9]$/),
    },
  },
  methods: {
    async requestOTP() {
      this.$refs.OtpInput.handleClearInput()
      this.isRequestOTP = true
      await moomallServices
        .getSendOtp(this.tel)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.data.time_left) {
              this.time_left = resp.data.data.time_left
            } else {
              this.time_left = 120
            }
            this.coundownOTP()
          }
        })
        .catch(() => {
          this.isRequestOTP = false
        })
    },
    sendOtp() {
      this.$refs.OtpInput.sendOtp()
    },
    coundownOTP() {
      this.intervalOTPKey = setInterval(() => {
        if (this.time_left > 0) {
          this.time_left -= 1
        } else {
          this.isRequestOTP = false
          clearInterval(this.intervalOTPKey)
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.otp-desc-form {
  padding: 0;
}

.title-otp {
  font-size: 30px;
}

.btn-danger {
  background-color: #da1f28;
}

.btn-lg {
  height: 50px;
}

.subtitle-otp {
  font-size: 20px;
  color: #6d6d6d;
}

.box-otp {
  font-size: 18px;
}

.otp-require {
  color: #6d6d6d;
}

.otp-resent {
  color: #da1f28;
  cursor: pointer;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  border-color: #da1f28 !important;
}

.subtitle-otp {
  color: #da1f28;
  font-size: 21px;
}

.btn-time-left {
  border-radius: 6px;
  background-color: #cbcbcb;
  color: white;
  width: 80px;
  height: auto;
  padding: 0;
}

.tel-input {
  width: 70% !important;
  margin: 0 auto;
  border-color: #da1f28;
  max-width: 360px;
}

.otp-btn {
  padding: 4px 8px;
  height: auto;
  width: 100px;
}

@media screen and (max-width: 480px) {
  .title-otp {
    font-size: 21px;
  }

  .subtitle-otp {
    font-size: 15px;
  }

  .box-otp {
    font-size: 12px;
  }
}
</style>
