require('@fortawesome/fontawesome-free/js/brands')
require('@fortawesome/fontawesome-free/js/solid')
require('@fortawesome/fontawesome-free/js/fontawesome')

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
require('bootstrap')

try {
  window.Popper = require('popper.js').default
  window.$ = window.jQuery = require('jquery')
} catch (e) {
  console.log('bootstrap:', e)
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const bsCustomFileInput = require('bs-custom-file-input/dist/bs-custom-file-input')

window.bsCustomFileInput = bsCustomFileInput

window.$(document).ready(function () {
  bsCustomFileInput.init()
})
