<template>
  <div>
    <div
      class="redeem-detail px-3 mt-3"
      v-for="item in redeemLocation.rows"
      :key="item.id"
    >
      <location
        class="redeem-detail-item"
        :img="item.img"
        :name="item.name"
        :address="item.address"
        :phone="item.phone"
        :border="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redeemLocation: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.redeem-detail {
  &-item {
    padding: 0.8rem;
    max-height: 115px;
  }
}
</style>
