<template>
  <div class="evoucher-instro container">
    <div class="evoucher-instro-title mb-2">สิ่งที่คุณจะได้รับ</div>
    <div class="evoucher-instro-description">
      {{ serviceDesc }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'evoucher-instro',
  props: {
    serviceDesc: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.evoucher-instro {
  padding: 1rem;
  border-radius: 14px;
  background-color: white;

  &-title {
    font-size: 20px;
    font-weight: bold;
    color: #bebebe;

    @media screen and (max-width: 480px) {
      font-size: 10px;
    }
  }

  &-description {
    white-space: pre-wrap;
    font-size: 22px;
    color: #453d3d;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
}
</style>
