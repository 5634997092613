<template>
  <div class="share-footer-button">
    <button
      @click="emitClick"
      :disabled="disabled"
      class="btn share-footer-button__btn"
    >
      {{ msg }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ShareFooterButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: 'ยืนยัน',
    },
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.share-footer-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  width: 100%;
  height: 83px;
  padding: 2rem 1rem;

  left: 0;
  bottom: 0;
  position: fixed;

  &__btn {
    color: white;
    border-radius: 14px;
    background-color: #da3d3c;

    font-size: 18px;
    font-weight: bold;

    width: 100%;
    height: 48px;
  }

  .btn:disabled {
    color: #bebebe;
    background-color: #eaeaea;
  }

  @media screen and (min-width: 576px) {
    // Unset to apply container and align at center
    left: unset;
  }
}
</style>
