import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL || '',
  mode: 'history',
  routes: [
    {
      path: '/pdpa',
      component: () => import('./pages/PDPA.vue'),
    },
    {
      path: '/version',
      component: () => import('./pages/Version.vue'),
    },
    {
      path: '/redeem_location',
      component: () => import('./pages/RedeemLocationMainPage.vue'),
    },
    {
      path: '/:salepageName',
      component: () => import('./pages/SalePage.vue'),
    },
    {
      path: '/:salepageName/otp_verification',
      component: () => import('./pages/OtpVerificationMainPage.vue'),
    },
    {
      path: '/:salepageName/create_order',
      component: () => import('./pages/OrderSummaryMainPage.vue'),
    },
    {
      path: '/:salepageName/generate_qr_code',
      component: () => import('./pages/CreateQrCodeMainPage.vue'),
    },
    {
      path: '/:salepageName/payment_success',
      component: () => import('./pages/PaymentSuccessMainPage.vue'),
    },
    {
      path: '*',
      component: () => import('./pages/NotFound.vue'),
    },
  ],
})

export default router
