<template>
  <div class="page">
    <main class="page-main">
      <product-header>{{ productCoach.welcome_title }}</product-header>

      <div class="page-main-detail">
        <product-image
          class="page-main-detail-img"
          :pathImage="welcomeImage.id"
          urlImage="file/"
          v-if="welcomeImage"
        >
        </product-image>
        <product-image-carousel
          class="page-main-detail-carousel"
          :productImageSlide="voucherDetail.productPic"
        >
        </product-image-carousel>

        <product-message
          class="product-message"
          :message="productCoach.welcome_message"
        ></product-message>
      </div>

      <div
        id="redeem-location"
        class="container py-2 mb-2 bg-products pb-2 redeem"
      >
        <redeem-location :evoucherDetail="voucherDetail"></redeem-location>
      </div>

      <div
        id="product-review"
        class="container px-0 border-bottom"
        v-if="
          voucherDetail.commentsNumber > 0 && voucherDetail.commentsList != null
        "
      >
        <product-review
          :productReview="voucherDetail.commentsList"
          :voucherDetail="voucherDetail"
        >
        </product-review>
      </div>

      <evoucher-instro
        class="my-3"
        :serviceDesc="voucherDetail.serviceDesc"
      ></evoucher-instro>

      <evoucher-condition
        class="my-3"
        :termsConditions="voucherDetail.termsConditions"
      ></evoucher-condition>

      <div class="product-sku">
        <div class="container bg-products bg-sku">
          <product-sku
            :productSku="this.productGoods"
            :productDetail="voucherDetail"
            :productCoach="productCoach"
            :productName="voucherDetail.productName"
            @updateOrder="updateOrder"
            :previewId="parseInt(previewId)"
          >
          </product-sku>
        </div>
      </div>

      <product-order
        class="py-2 mb-2 bg-products product-order"
        :productOrder="this.productOrder"
      >
      </product-order>

      <evoucher-form
        class="container mb-2 bg-products"
        :createOrderUrl="createOrderUrl"
        :product="productOrder"
        :previewId="parseInt(previewId)"
        :productCoach="productCoach"
        :state="voucherDetail.state"
        :offshelf="productCoach.offshelf"
      >
      </evoucher-form>
    </main>

    <div
      class="alert alert-dark alert-dismissible fade show fixed-bottom"
      role="alert"
      v-show="PDPA === null && parseInt(previewId) !== 0"
    >
      <div class="mb-2">
        <span class="text-center">
          เว็บไซต์ของเรามีการเก็บ cookies
          ซึ่งเก็บข้อมูลว่าคุณใช้งานเว็บไซต์ของเราอย่างไรและช่วยให้เราจดจำคุณได้
          เราใช้ข้อมูลนี้เพื่อการทำ analytics
          และนำมาสู่การทำให้ประสบการณ์การใช้เว็บไซต์ดียิ่งขึ้น
        </span>
      </div>
      <div class="alert-btn d-flex justify-content-end">
        <button
          @click="NotConfirmPDPA()"
          type="button"
          class="btn alert-btn-no mr-3"
        >
          ปฏิเสธ
        </button>
        <button
          @click="ConfirmPDPA()"
          type="button"
          class="btn alert-btn-confirm"
        >
          ยินยอม
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moomallService from '@/services/moomallServices'

export default {
  name: 'ProductsPageMain',
  props: {
    product: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    welcomeImage: {
      type: Object,
    },
    reviewImages: {
      type: Array,
    },
    createOrderUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      voucherDetail: Object,
      productGoods: Object,
      specInfo: '',
      productOrder: {},
      reviewProfileImage: null,
    }
  },
  computed: {
    ...mapGetters({ PDPA: 'ProductStore/PDPA' }),
  },
  async created() {
    this.voucherDetail = this._.get(this.product.data, 'voucherDetail')
    this.productGoods = this._.get(this.product.data, 'productGoods')

    this.productGoods.normList.map((item, index) => {
      if (index < 1) {
        item.attrList.map(spec => {
          this.specInfo += spec.name + ';'
        })
      }
    })

    this.productGoods.goods.map((item, index) => {
      this.productGoods.goods[index].normAttrId = item.normAttrId.split(',')
    })

    // Set type to carousel for image only
    this.voucherDetail.productPic.map(p => {
      if (p.type !== 'video') {
        p.type = 'main-carousel'
      }
      return p
    })

    // GTAG
    this.$gtag.pageview({
      page_title: this.productCoach.welcome_title + '- main',
      page_location: window.location.href,
      page_path: this.productCoach.route_name,
      product_coach_id: this.productCoach.id,
    })

    try {
      const url = `/member/${this.productCoach.invite_code}`
      const res = await moomallService.get(url)
      if (res.status === 200) {
        const member = res.data.data
        this.reviewProfileImage = member.headImg
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    ...mapMutations({ getComfirmPDPA: 'ProductStore/SET_COMFIRM_PDPA' }),
    updateOrder(order) {
      this.productOrder = order
      this.productOrder = {
        ...this.productOrder,
        ...{
          companyName: this.voucherDetail.companyName,
          name1: this.voucherDetail.name1,
        },
      }
    },
    ConfirmPDPA() {
      this.getComfirmPDPA(true)
    },
    NotConfirmPDPA() {
      this.getComfirmPDPA(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  &-main {
    &-detail {
      margin-top: 82px;

      &-img {
        min-width: 375px;
        min-height: 375px;
      }

      &-carousel {
        min-width: 375px;
        min-height: 375px;
      }
    }

    .product-sku {
      .title {
        text-align: center;
        font-size: 14px;
        font-weight: bold;

        margin-bottom: 8px;
      }

      .bg-sku {
        border-radius: 10px;
      }
    }

    .product-order {
      margin-top: 10px;
    }
  }
}

.product-header {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 30px;
  z-index: 99;
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
}

.product-message {
  margin-top: 16px;
  margin-bottom: 15px;
}

.bg-products {
  background-color: #ffffff;
}

// above is new css

.border-bottom {
  border-bottom: 20px solid #f8f8f8 !important;
}

.alert {
  margin-bottom: 0px;
  padding: 1rem 1rem 1.5rem 1rem;
  color: #453d3d;
  font-size: 13px;
  z-index: 99999999999;

  &-btn {
    &-no {
      color: #dbdbdb;
      border: 1px solid #eaeaea;
      font-size: 16px;
      border-radius: 14px;
    }

    &-confirm {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 14px;
      background-color: #da3d3c;
    }
  }

  &-dark {
    background-color: #fff;
  }
}

.redeem {
  border-radius: 14px;
  max-height: 200px;

  @media screen and (max-width: 480px) {
    max-height: 148px;
  }
}

@media screen and (max-width: 480px) {
  .border-bottom {
    border-bottom: 10px solid #f8f8f8 !important;
  }
}
</style>
