<template>
  <nav
    class="share-header navbar navbar-expand-lg"
    style="height: 50px; color: #f8f6f6"
  >
    <div class="container-fluid">
      <button class="btn rounded-0 btn-sm" @click="redirect">
        <img
          src="@/assets/icon-back-red.svg"
          width="6px"
          height="11px"
          alt="Icon Back"
        />
      </button>
      <div class="mx-auto">
        <div>{{ header }}</div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'ShareHeader',
  props: {
    header: {
      type: String,
      require: true,
    },
  },
  methods: {
    redirect() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.share-header {
  background-color: #f8f6f6;
  color: #da3d3c !important;
  font-size: 24px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
}
</style>
