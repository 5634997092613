var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ProductSku"}},[_c('div',{staticClass:"title-sku"},[_vm._v("ตัวเลือกสินค้า")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"sku-body"},[_c('div',{staticClass:"image-sku"},[_c('product-image',{attrs:{"pathImage":_vm.selectGood.masterImg}})],1),_c('div',{staticClass:"detail-sku"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productCoach.type === 2),expression:"productCoach.type === 2"}],staticClass:"merchant-brand"},[_vm._v(" "+_vm._s(_vm.productName)+" ")]),(_vm.productCoach.type === 2)?_c('div',{staticClass:"product-price"},[_c('span',{staticClass:"money main-price"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.selectGood.memberPrice) || '0'))]),(
              parseFloat(_vm.selectGood.stickerPrice) >
              parseFloat(_vm.selectGood.memberPrice)
            )?_c('span',{staticClass:"sticker-price money"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.selectGood.stickerPrice))+" ")]):_vm._e(),(
              _vm.selectGood.discountPercentage != null &&
              _vm.selectGood.discountPercentage > 0
            )?_c('span',{staticClass:"discount"},[_vm._v(" -"+_vm._s(_vm.selectGood.discountPercentage)+"% ")]):_vm._e()]):_c('div',{staticClass:"product-price"},[_c('span',{staticClass:"money main-price"},[_vm._v(" "+_vm._s((_vm.selectGood.isFlash > 0 ? _vm.formatPrice(_vm.selectGood.flashPrice) : _vm.formatPrice(_vm.selectGood.memberPrice)) || _vm.formatPrice(_vm.productDetail.skuMinPrice))+" ")]),(
              _vm.selectGood.isFlash == 0 &&
              parseFloat(_vm.selectGood.stickerPrice) >
                parseFloat(_vm.selectGood.memberPrice)
            )?_c('span',{staticClass:"sticker-price money"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.selectGood.stickerPrice))+" ")]):_vm._e(),(
              _vm.selectGood.isFlash == 0 &&
              _vm.selectGood.discountPercentage != null &&
              _vm.selectGood.discountPercentage > 0
            )?_c('span',{staticClass:"discount"},[_vm._v(" -"+_vm._s(_vm.selectGood.discountPercentage)+"% ")]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productCoach.type === 1),expression:"productCoach.type === 1"}],staticClass:"product-quantity"},[_vm._v(" มีสินค้า "+_vm._s(_vm.selectStock)+" ")]),_vm._l((_vm.specSelected),function(sItem,sIndex){return _c('div',{key:sIndex,staticClass:"product-select"},[_c('span',{staticClass:"select"},[_vm._v("เลือก")]),_vm._v(" "+_vm._s(sItem.name)+" ")])})],2)])]),_c('v-divider',{staticClass:"mx-1 my-3"}),_c('div',{staticClass:"product-select-type"},[_vm._l((this.productSku.normList),function(item,index){return _c('div',{key:index,staticClass:"attr-list"},[_c('div',{staticClass:"title-spec"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-list"},_vm._l((item.attrList),function(childItem,childIndex){return _c('div',{key:childIndex,staticClass:"box-select",class:[{ selected: childItem.selected == 1 }],on:{"click":function($event){return _vm.selectSpec(childIndex, item.name)}}},[_vm._v(" "+_vm._s(childItem.name)+" ")])}),0)])}),_c('v-divider',{staticClass:"mx-1 my-2"}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"title-type-quantity my-auto"},[_vm._v("จำนวน")]),_c('div',{staticClass:"my-auto"},[_c('number-input',{staticClass:"number-input",attrs:{"size":"small","inline":"","center":"","controls":"","inputtable":false,"disabled":_vm.previewId == 0,"min":_vm.selectStock <= 0 ? 0 : 1,"max":_vm.selectStock <= 0 ? 0 : _vm.selectStock},on:{"change":_vm.createOrder},model:{value:(_vm.selectNum),callback:function ($$v) {_vm.selectNum=$$v},expression:"selectNum"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }