<template>
  <div class="product-form">
    <div class="product-form__title">
      <div>ที่อยู่สำหรับจัดส่ง</div>
      <div @click="pdpa" class="product-form__title--gray">
        นโยบายความเป็นส่วนตัว
        <img
          src="@/assets/icon-next-gray.svg"
          class="ml-1"
          width="6"
          height="11"
          alt="Icon Next"
        />
      </div>
    </div>
    <div class="product-form__input">
      <form @submit.prevent>
        <div class="form-row">
          <div class="product-form__input-control col-6">
            <label for="firstNameInput">
              <span class="text-danger">*</span>
              ชื่อ - สกุล
            </label>
            <input
              type="text"
              class="form-control"
              id="firstNameInput"
              v-model.trim="$v.name.$model"
              :class="{
                'is-invalid': $v.name.$error,
                'is-valid': !$v.name.$invalid,
              }"
              placeholder="กรอกชื่อ - สกุล"
              :disabled="previewId == 0"
              value=""
            />
          </div>

          <div class="product-form__input-control col-6">
            <label for="tel-input">
              <span class="text-danger">*</span>
              เบอร์โทรศัพท์ผู้รับสินค้า
            </label>
            <input
              class="form-control"
              type="tel"
              value=""
              id="tel-input"
              v-model.trim="$v.tel.$model"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              :class="{
                'is-invalid': $v.tel.$error,
                'is-valid': !$v.tel.$invalid,
              }"
              :disabled="previewId == 0"
              placeholder="กรอกเบอร์โทรศัพท์มือถือ"
            />
          </div>

          <div class="product-form__input-control col-12">
            <label for="inputAddress">
              <span class="text-danger">*</span>
              ที่อยู่
            </label>
            <input
              type="text"
              class="form-control"
              id="inputAddress"
              v-model.trim="$v.address.$model"
              :class="{
                'is-invalid': $v.address.$error,
                'is-valid': !$v.address.$invalid,
              }"
              :disabled="previewId == 0"
              placeholder="กรอกที่อยู่สำหรับจัดส่ง"
            />
          </div>

          <div class="product-form__input-control col-6">
            <label for="inputProvince">
              <span class="text-danger">*</span>
              จังหวัด
            </label>
            <select
              id="inputProvince"
              class="form-control"
              v-model.trim="$v.province.$model"
              :class="{
                'is-invalid': $v.province.$error,
                'is-valid': !$v.province.$invalid,
              }"
              @change="selectProvince(province)"
              :disabled="previewId == 0"
            >
              <option
                class="product-form__input-control--default-selected"
                value=""
              >
                เลือกจังหวัด
              </option>
              <option
                v-for="item in this.provinces"
                :key="item.id"
                :value="{ id: item.id, text: item.regionName }"
              >
                {{ item.regionName }}
              </option>
            </select>
          </div>

          <div class="product-form__input-control col-6">
            <label for="inputDistrict">
              <span class="text-danger">*</span>
              เขต/อำเภอ
            </label>
            <select
              id="inputDistrict"
              class="form-control"
              v-model.trim="$v.district.$model"
              :class="{
                'is-invalid': $v.district.$error,
                'is-valid': !$v.district.$invalid,
              }"
              @change="selectDistrict(district)"
              :disabled="previewId == 0"
            >
              <option selected="selected" value="">เลือกเขต/อำเภอ</option>
              <option
                v-for="item in this.districts"
                :key="item.id"
                :value="{ id: item.id, text: item.regionName }"
              >
                {{ item.regionName }}
              </option>
            </select>
          </div>

          <div class="product-form__input-control col-6">
            <label for="inputSub-district">
              <span class="text-danger">*</span>
              แขวง/ตำบล
            </label>
            <select
              id="inputSub-district"
              class="form-control"
              v-model.trim="$v.sub_district.$model"
              :class="{
                'is-invalid': $v.sub_district.$error,
                'is-valid': !$v.sub_district.$invalid,
              }"
              @change="selectSub_district(sub_district)"
              :disabled="previewId == 0"
            >
              <option selected="selected" value="">เลือกแขวง/ตำบล</option>
              <option
                v-for="item in this.sub_districts"
                :key="item.regionName"
                :value="{ text: item.regionName, pid: item.parentId }"
              >
                {{ item.regionName }}
              </option>
            </select>
          </div>

          <div class="product-form__input-control col-6">
            <label for="inputZip">
              <span class="text-danger">*</span>
              รหัสไปรษณีย์
            </label>
            <select
              id="inputZip"
              class="form-control"
              v-model.trim="$v.zipcode.$model"
              :class="{
                'is-invalid': $v.zipcode.$error,
                'is-valid': !$v.zipcode.$invalid,
              }"
              :disabled="previewId == 0"
              @change="selectZipcode(zipcode)"
            >
              <option selected="selected" value="">เลือกรหัสไปรษณีย์</option>
              <option
                v-for="item in zipcodes"
                :key="item.id"
                :value="item.postalCode"
              >
                {{ item.postalCode }}
              </option>
            </select>
          </div>

          <div class="product-form__input-control col-12">
            <label for="inputEmail4">
              <span class="text-danger">*</span>
              อีเมล
              <span class="email-notice"
                >(หลักฐานการสั่งซื้อและหมายเลขติดตามสินค้าจะถูกส่งไปยังอีเมลนี้)</span
              >
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              v-model.trim="$v.email.$model"
              :class="{
                'is-invalid': $v.email.$error,
                'is-valid': !$v.email.$invalid,
              }"
              placeholder="กรอกอีเมลของคุณ"
              :disabled="previewId == 0"
              value=""
            />
          </div>
        </div>
      </form>
      <snack-bar
        v-model="snackbar"
        :message="message"
        icon="mdi-information-outline"
      />
    </div>

    <product-footer
      :previewId="previewId"
      @onSubmit="onSubmit"
      :soldOut="product.productStock"
      :state="state"
      :offshelf="offshelf"
      :lineAccount="productCoach.line_account"
    >
    </product-footer>
  </div>
</template>
<script>
import {
  email,
  required,
  numeric,
  minLength,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators'
import { mapMutations, mapGetters } from 'vuex'
import moomallServices from '../../services/moomallServices'

export default {
  name: 'ProductForm',
  props: {
    createOrderUrl: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    state: {
      type: [Number, String],
      required: true,
    },
    offshelf: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      UserInfo: 'ProductStore/UserInfo',
      PDPA: 'ProductStore/PDPA',
      ProductDetail: 'ProductStore/ProductDetail',
      AddressShipping: 'ProductStore/AddressShipping',
    }),
  },
  mounted() {
    if (Number(this.$route.query?.previewId) !== 0) {
      this.name = this.UserInfo.name || ''
      this.email = this.UserInfo.email || ''
      this.tel = this.UserInfo.tel || ''
      this.zipcode = this.UserInfo.zipcode || ''
      this.address = this.UserInfo.address || ''
      this.province = this.UserInfo.province || ''
      this.district = this.UserInfo.district || ''
      this.distinct_sub_district = this.UserInfo.sub_district || ''

      this.sub_district =
        this.distinct_sub_district !== ''
          ? {
              text: this.distinct_sub_district.text,
              pid: this.distinct_sub_district.pid,
            }
          : ''
    }

    this.onLoad()

    if (!this.PDPA) {
      this.removeUserInfo()
    }
  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      zipcode: '',
      address: '',
      province: '',
      provinces: [],
      district: '',
      districts: [],
      sub_district: '',
      sub_districts: '',
      timestamp: '',
      zipcodes: [],
      distinct_sub_district: null,
      snackbar: false,
      message: '',
    }
  },
  methods: {
    ...mapMutations({
      setUserInfo: 'ProductStore/SET_USER_INFO',
      setProductDetail: 'ProductStore/SET_PRODUCT_DETAIL',
      setAddressShipping: 'ProductStore/SET_ADDRESS_SHIPPING',
      removeUserInfo: 'ProductStore/REMOVE_USER_INFO',
    }),
    async onLoad() {
      this.provinces = []
      this.districts = []
      this.sub_districts = []
      this.zipcodes = []

      await moomallServices.getProvice().then(resp => {
        if (resp.status === 200) {
          this.provinces = resp.data.data || []
        }
      })

      if (this.province !== '') {
        await moomallServices.getDistrict(this.province.id).then(resp => {
          if (resp.status === 200) {
            this.districts = resp.data.data || []
          }
        })
      }

      if (this.district !== '') {
        const subDistrictRes = await moomallServices.get(
          `/address/distinct_sub_district/${this.district.id}`,
        )
        if (subDistrictRes.status === 200) {
          this.sub_districts = subDistrictRes.data.data || []
        }
      }

      if (this.zipcode !== '') {
        const zipcodeRes = await moomallServices.get(
          `/address/zipcode?name=${this.sub_district.text}&pid=${this.sub_district.pid}`,
        )
        if (zipcodeRes.status === 200) {
          this.zipcodes = zipcodeRes.data.data || []
        }
      }
    },
    async onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return this.validateHandler()
      } else {
        if (this.product.selectNumTotal > 0) {
          let userInfo = {
            name: this.name,
            email: this.email,
            tel: this.tel,
            zipcode: this.zipcode,
            address: this.address,
            province: this.province,
            district: this.district,
            sub_district: this.distinct_sub_district,
          }

          await this.setUserInfo(userInfo)

          const currentDate = new Date()
          this.timestamp = this.$route.query.spTimestamp
            ? this.$route.query.spTimestamp
            : currentDate.getTime()
          await this.setProductDetail({
            data: this.product,
            time: this.timestamp,
          })
          this.setAddressShipping({
            data: this.UserInfo,
            id: `sp_${this.timestamp}`,
          })

          const orderData = this.prepareDataCreateOrder(this.timestamp)
          this.$router.push({
            path: this.createOrderUrl,
            query: {
              ...orderData,
            },
          })
        }
      }
    },
    async selectProvince(province) {
      this.districts = []
      this.district = ''
      this.sub_districts = []
      this.sub_district = ''
      this.zipcode = ''

      if (this.province !== '') {
        await moomallServices.getDistrict(province.id).then(resp => {
          if (resp.status === 200) {
            this.districts = resp.data.data || []
          }
        })
      }
    },
    async selectDistrict(district) {
      this.sub_districts = []
      this.sub_district = ''
      this.zipcode = ''

      if (this.district !== '') {
        const subDistrictRes = await moomallServices.get(
          `/address/distinct_sub_district/${district.id}`,
        )
        if (subDistrictRes.status === 200) {
          this.sub_districts = subDistrictRes.data.data || []
        }
      }
    },
    async selectSub_district() {
      this.zipcode = ''

      if (this.sub_district !== '') {
        const zipcodeRes = await moomallServices.get(
          `/address/zipcode?name=${this.sub_district.text}&pid=${this.sub_district.pid}`,
        )
        if (zipcodeRes.status != 200) {
          return
        }
        this.zipcodes = zipcodeRes.data.data || []
        if (this.zipcodes.length === 1) {
          const tmpZipcode = this.zipcodes[0]
          this.zipcode = tmpZipcode.postalCode
          this.distinct_sub_district = {
            id: tmpZipcode.id,
            text: tmpZipcode.regionName,
            postalCode: tmpZipcode.postalCode,
            pid: this.sub_district.pid,
          }
        }
      }
    },
    selectZipcode(postalCode) {
      /* From previous requirement with auto selct zipcode on MMTH-59
       ** When sub district have only one zipcode we will pick it auto
       ** Then if this function has been call it mean we have more than 1 zipcode
       ** So just check and find with `postalCode` and set it to `distinct_sub_district`
       */
      if (this.zipcodes.length > 1) {
        const zipcode = this.zipcodes.find(
          zipcode => zipcode.postalCode === postalCode,
        )
        this.distinct_sub_district = {
          id: zipcode.id,
          text: zipcode.regionName,
          postalCode: zipcode.postalCode,
          pid: this.sub_district.pid,
        }
      }
    },
    prepareDataCreateOrder(timestamp) {
      const product = this.ProductDetail(`sp_${timestamp}`)
      const address = this.AddressShipping(`sp_${timestamp}`)
      if (this.productCoach.type === 1) {
        return {
          productId: product.productId,
          inviteCode: this.productCoach.invite_code,
          productGoodsId: product.id,
          number: product.selectNumTotal,
          spTimestamp: timestamp,
          mobile: address.tel,
          receiver: address.name,
          provinceId: address.province.id,
          provinceName: address.province.text,
          cityId: address.district.id,
          cityName: address.district.text,
          areaId: address.sub_district.id,
          areaName: address.sub_district.text,
          addressInfo: address.address,
          zipCode: address.zipcode,
          email: address.email,
        }
      } else {
        return {
          productId: product.productId,
          inviteCode: this.productCoach.invite_code,
          productGoodsId: product.id,
          number: product.selectNumTotal,
          spTimestamp: timestamp,
          mobile: address.tel,
          receiver: address.name,
          email: address.email,
        }
      }
    },
    validateHandler() {
      // NOTE: Manual check and alert popup, True = valid | False = invalid
      // Name section
      if (!this.$v.name.required) {
        return this.snackbarHandler('กรุณากรอกข้อมูลผู้ซื้อ')
      }
      if (!this.$v.name.maxLength) {
        return this.snackbarHandler(
          `ห้ามกรอกชื่อ - สกุล เกิน ${this.$v.name.$params.maxLength.max} ตัว`,
        )
      }

      // Phone section
      if (!this.$v.tel.required) {
        return this.snackbarHandler('กรุณากรอกเบอร์โทรศัพท์มือถือ')
      }
      if (!this.$v.tel.isTelValid) {
        return this.snackbarHandler('กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง')
      }

      // Address section
      if (!this.$v.address.required) {
        return this.snackbarHandler('กรุณากรอกที่อยู่สำหรับจัดส่ง')
      }
      if (!this.$v.address.maxLength) {
        return this.snackbarHandler(
          `ห้ามกรอกชื่อ - สกุล เกิน ${this.$v.address.$params.maxLength.max} ตัว`,
        )
      }

      // Province section
      if (!this.$v.province.required) {
        return this.snackbarHandler('กรุณาเลือกจังหวัด')
      }

      // District section
      if (!this.$v.district.required) {
        return this.snackbarHandler('กรุณาเลือกเขต/อำเภอ')
      }

      // Sub District section
      if (!this.$v.sub_district.required) {
        return this.snackbarHandler('กรุณาเลือกแขวง/ตำบล')
      }

      // Zipcode
      if (!this.$v.zipcode.required) {
        return this.snackbarHandler('กรุณาเลือกรหัสไปรษณีย์')
      }

      // Email section
      if (!this.$v.email.required) {
        return this.snackbarHandler('กรุณากรอกอีเมลของคุณ')
      }
      if (!this.$v.email.email) {
        return this.snackbarHandler('กรุณาระบุอีเมลให้ถูกต้อง')
      }
    },
    snackbarHandler(message) {
      this.snackbar = true
      this.message = message
    },
    pdpa() {
      this.$router.push('/pdpa')
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(128),
    },
    email: {
      required,
      email,
    },
    tel: {
      required,
      isTelValid: helpers.regex('isTelValid', /^[0](?:[0-9] ?){8}[0-9]$/),
    },
    zipcode: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(5),
    },
    address: {
      required,
      maxLength: maxLength(500),
    },
    province: {
      required,
    },
    district: {
      required,
    },
    sub_district: {
      required,
    },
  },
}
</script>

<style lang="scss" scoped>
.product-form {
  border-radius: 10px 10px 0 0;
  padding: 1rem 1rem 0.5rem 1rem;

  &__title {
    display: flex;
    justify-content: space-between;

    font-size: 29px;
    color: #333333;
    border-bottom: 0.5px solid #fbe7e7;
    padding-bottom: 6px;

    &--gray {
      color: #bebebe;
    }
  }

  &__input {
    &-control {
      padding-top: 4px;
      padding-bottom: 4px;

      .email-notice {
        font-size: 24px;
        color: #da3d3c;
      }

      &--default-selected {
        color: #bebebe;
      }
    }

    .form-control {
      font-size: 29px;
      color: #453d3d;
      background-color: #fffafa;

      border: 1px solid #fcefef;
      border-radius: 14px;
    }

    .form-control:focus {
      border-color: #fcefef;
      box-shadow: 0 0 0 0.2rem #fcefef;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
      background-image: none;
      border-color: #fcefef;
    }

    .form-control.is-valid:focus {
      box-shadow: 0 0 0 0.2rem rgba($color: #fcefef, $alpha: 0.75);
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: none;
      border-color: #453d3d;
    }

    .form-control.is-invalid:focus {
      box-shadow: 0 0 0 0.2rem rgba($color: #453d3d, $alpha: 0.5);
    }

    label {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  .product-form {
    &__title {
      font-size: 12px;
    }

    &__input {
      &-control {
        .email-notice {
          font-size: 10px;
        }
      }

      .form-control {
        font-size: 14px;
        height: 44px;
      }

      label {
        font-size: 10px;
      }
    }
  }
}
</style>
