<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    style="height: 50px"
  >
    <div class="container-fluid">
      <div class="mx-auto">
        <div class="title-header">การชำระเงินสำเร็จ</div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PaymentSuccessHeader',
  data() {
    return {}
  },
}
</script>

<style scoped>
.title-header {
  font-size: 24px;
}

@media screen and (max-width: 480px) {
  .title-header {
    font-size: 16px;
  }
}
</style>
