import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import { ProductStore } from './Main/ProductStore/ProductStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ProductStore,
  },
  plugins: [
    createPersistedState(
      {
        key: 'salepage',
        paths: ['ProductStore'],
      },
      { storage: window.sessionStorage },
    ),
  ],
})
