<template>
  <div id="OrderSummary">
    <div class="title-order-summary mb-2">
      สรุปคำสั่งซื้อ ({{
        ProductDetail(`sp_${this.products.spTimestamp}`).selectNumTotal
      }}
      ชิ้น)
    </div>

    <div class="d-flex justify-content-between subtitle-order">
      <div class="my-auto">ยอดรวม:</div>
      <div class="my-auto">฿{{ convertPrice }}</div>
    </div>

    <div
      class="d-flex justify-content-between subtitle-order"
      v-if="productCoach.type === 1"
    >
      <div class="my-auto">ค่าจัดส่ง:</div>
      <div class="my-auto">฿{{ convertTotalFreight }}</div>
    </div>

    <div class="d-flex justify-content-between subtitle-order">
      <div class="my-auto">ส่วนลด:</div>
      <div class="my-auto">-฿{{ convertPriceDiscount }}</div>
    </div>

    <div class="d-flex justify-content-between total-order">
      <div class="my-auto">ยอดรวมทั้งหมด:</div>
      <div class="my-auto total-price">฿{{ convertPriceString }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as numeral from 'numeral'

export default {
  name: 'OrderSummary',
  props: {
    order: {
      type: Object,
      require: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      ProductDetail: 'ProductStore/ProductDetail',
    }),
    convertTotalFreight() {
      const priceString = this.order.totalFreight
      return this.formatPrice(priceString)
    },
    convertPriceString() {
      const priceString = this.order.totalOrderPrice
      return this.formatPrice(priceString)
    },
    convertPrice() {
      const priceString = this.ProductDetail(
        `sp_${this.products.spTimestamp}`,
      ).totalPrice
      return this.formatPrice(priceString)
    },
    convertPriceDiscount() {
      let priceString = this.order.discountThb
      return this.formatPrice(priceString)
    },
  },
  methods: {
    formatPrice(price) {
      return numeral(price).format('0,0.00')
    },
  },
}
</script>

<style scoped>
.title-order-summary {
  font-size: 26px;
}

.total-order {
  font-size: 26px;
}

.total-price {
  color: #da2a24;
}

.subtitle-order {
  font-size: 26px;
  color: #999999;
}

@media screen and (max-width: 480px) {
  .title-order-summary {
    font-size: 15px;
  }

  .total-order {
    font-size: 13px;
  }

  .subtitle-order {
    font-size: 13px;
  }
}
</style>
