<template>
  <star-rating
    :increment="0.5"
    :read-only="true"
    :star-size="10"
    :show-rating="false"
    inactive-color="white"
    active-color="#DA3D3C"
    :border-width="1"
    border-color="#DA3D3C"
    :rating="ratingStar"
  ></star-rating>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: 'ProductReviewRating',
  components: {
    StarRating,
  },
  props: {
    ratingStar: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>

<style scoped></style>
