<template>
  <div id="ProductFooter" class="footer">
    <line-info :previewId="previewId" :lineAccount="lineAccount"></line-info>
    <div class="footer-box">
      <button
        class="btn footer-box-btn"
        v-if="previewId == 0"
        :disabled="previewId == 0"
      >
        ตัวอย่าง เซลเพจ
      </button>
      <button
        v-else
        @click="onSubmit"
        class="btn footer-box-btn footer-box-btn--purchase"
        :disabled="soldOut === 0 || state === 3 || offshelf === 0"
      >
        {{ MessagePurchaseBtn }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductFooter',
  props: {
    soldOut: {
      type: [Number, String],
      default: 0,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    state: {
      type: [Number, String],
      required: true,
    },
    offshelf: {
      type: [Number, String],
      required: true,
    },
    lineAccount: {
      type: String,
      required: false,
    },
  },
  computed: {
    MessagePurchaseBtn() {
      const text =
        this.soldOut === 0
          ? this.state === 3 || this.offshelf === 0
            ? 'งดจำหน่าย'
            : 'สินค้าหมด'
          : this.state === 3 || this.offshelf === 0
          ? 'งดจำหน่าย'
          : 'ซื้อสินค้า'
      return text
    },
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;

  background-color: white;
  width: 100%;

  // position
  position: fixed;
  padding-top: 10px;
  padding-bottom: 25px;
  bottom: 0px;
  left: 0;
  z-index: 9999;

  &-box {
    &-btn {
      border-radius: 14px;
      width: 198px;
      height: 48px;
      font-size: 18px;
      margin-left: 10px;
      font-weight: bold;

      &--purchase {
        color: white;
        background-color: #da3d3c;
      }
    }
    .btn:disabled {
      color: #bebebe;
      background-color: #eaeaea;
    }
  }
}
</style>
