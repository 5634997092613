<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style lang="scss" scoped>
@import '@/scss/salepage.scss';

@font-face {
  font-family: 'Helvetica';
  src: url(./assets/fonts/Helvetica.ttf) format('truetype');
}

/* Bold */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: bold;
}

/* Light */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

#app {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $body-bg;
}
</style>
