<template>
  <div class="order-footer">
    <thai-qr-pay-label></thai-qr-pay-label>
    <share-footer-button
      class="container"
      @click="confirm"
    ></share-footer-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderFooter',
  props: {
    otpVerificationUrl: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      OrderSummary: 'ProductStore/OrderSummary',
      AddressShipping: 'ProductStore/AddressShipping',
    }),
  },
  data() {
    return {
      spTimestamp: '',
    }
  },
  created() {
    this.spTimestamp = this.$route.query.spTimestamp
  },
  methods: {
    confirm() {
      const query = this.prepareDataOtp()
      this.$router.push({
        path: this.otpVerificationUrl,
        query: {
          ...query,
        },
      })
    },
    prepareDataOtp() {
      console.log(
        'CLICK TO OTP',
        this.OrderSummary(`sp_${this.spTimestamp}`),
        this.AddressShipping(`sp_${this.spTimestamp}`),
      )
      const order = this.OrderSummary(`sp_${this.spTimestamp}`)
      const address = this.AddressShipping(`sp_${this.spTimestamp}`)
      return {
        id: order.orderId,
        orderIdSummary: order.id,
        // It has been hardcode from legacy and I don't know why fix value of type = 1
        type: 1,
        orderSn: order.orderSn,
        payMoney: order.totalOrderPrice,
        mobile: address.tel,
        spTimestamp: this.spTimestamp,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.order-footer {
  // Remove padding
  padding: 0;
}
</style>
