<template>
  <div
    id="OrderSummaryMainPage"
    v-if="
      this.ProductDetail &&
      this.ProductDetail(`sp_${this.products.spTimestamp}`)
    "
  >
    <order-summary-header
      v-if="order && products && productCoach"
    ></order-summary-header>
    <main>
      <div
        v-if="order && products && productCoach"
        id="order-detail"
        class="container py-2 mb-1 bg-products"
      >
        <order-description
          :products="products"
          :productCoach="productCoach"
          :freeFreight="freeFreightAmount"
        ></order-description>
      </div>

      <div
        v-if="order && products && productCoach"
        id="order-summary"
        class="container py-2 mb-1 bg-products"
      >
        <order-summary
          :order="order"
          :products="products"
          :productCoach="productCoach"
        ></order-summary>
      </div>

      <div
        v-if="order && products && productCoach"
        id="order-address"
        class="container py-2 bg-products"
      >
        <order-address
          v-if="order && products && productCoach"
          :order="order"
          :products="products"
          :productCoach="productCoach"
        ></order-address>
      </div>

      <order-footer
        class="container"
        v-if="order && products && productCoach"
        :order="order"
        :products="products"
        :otpVerificationUrl="otpVerificationUrl"
      ></order-footer>

      <snack-bar
        v-model="snackbar"
        :message="message"
        :show-btn="true"
        btn-color="#da3d3c"
        :white-mode="true"
        icon="mdi-information-outline"
        icon-size="28px"
        icon-color="#da3d3c"
        @click="snackbarOK"
      />
    </main>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapGetters } from 'vuex'
import moomallServices from '../services/moomallServices'

export default Vue.extend({
  name: 'OrderSummaryMainPage',
  data() {
    return {
      order: null,
      products: null,
      productCoach: null,
      otpVerificationUrl: null,
      freeFreightAmount: 0,
      snackbar: false,
      message: '',
    }
  },
  methods: {
    ...mapMutations({
      setOrderSummary: 'ProductStore/SET_ORDER_SUMMARY',
    }),
    onLoad() {
      this.setOrderSummary({
        data: this.order,
        id: `sp_${this.products.spTimestamp}`,
      })
    },
    snackbarOK() {
      this.$router.push(`/${this.productCoach.route_name}`)
    },
  },
  computed: {
    ...mapGetters({
      ProductDetail: 'ProductStore/ProductDetail',
      OrderSummary: 'ProductStore/OrderSummary',
      AddressShipping: 'ProductStore/AddressShipping',
    }),
  },
  async created() {
    this.products = this.$route.query
    const routeName = this.$route.params['salepageName']
    this.otpVerificationUrl = `/${routeName}/otp_verification`

    const productCoachResp = await moomallServices.get(
      `product_coach/name/${routeName}`,
    )

    const { productCoach, product } = productCoachResp.data

    this.productCoach = productCoach
    if (product?.data?.productDetail?.freeFreightAmount) {
      this.freeFreightAmount = product.data.productDetail.freeFreightAmount
    }

    try {
      const createOrderResp = await moomallServices.post(`order/${routeName}`, {
        ...this.products,
      })
      this.order = createOrderResp.data.data

      if (
        !this._.isEmpty(this.ProductDetail(`sp_${this.products.spTimestamp}`))
      ) {
        await this.onLoad()
      }

      if (
        this._.isEmpty(
          this.ProductDetail(`sp_${this.products.spTimestamp}`) &&
            this.OrderSummary(`sp_${this.products.spTimestamp}`) &&
            this.AddressShipping(`sp_${this.products.spTimestamp}`),
        )
      ) {
        return (location.href = `/${this.productCoach.route_name}`)
      }

      // GTAG
      this.$gtag.pageview({
        page_title: this.productCoach.welcome_title + '- summary',
        page_location: window.location.href,
        page_path: this.productCoach.route_name,
      })
    } catch (error) {
      const errRes = error.response
      this.snackbar = true
      this.message = errRes.data?.message
    }
  },
})
</script>

<style lang="scss" scoped>
.bg-products {
  background-color: #ffffff;
}
</style>
