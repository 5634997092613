import './global.scss'

import Vue from 'vue'
import App from './App.vue'
import VueCarousel from 'vue-carousel'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import OtpInput from '@bachdgvn/vue-otp-input'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'

require('./bootstrap')

// Gtag
import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'

import router from './router'
import store from './store'
import * as lodash from 'lodash'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.prototype._ = lodash

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
})
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
  defer: false,
  enabled: true,
  debug: true,
  loadScript: true,
  trackOnNextTick: false,
})

Vue.use(VueNumberInput)
Vue.use(VueCarousel)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Vuelidate)

// ProductsMainPage
Vue.component(
  'products-main-page',
  require('./pages/ProductsMainPage.vue').default,
)
Vue.component(
  'product-header',
  require('./components/share/ProductHeader.vue').default,
)
Vue.component(
  'product-message',
  require('./components/products/ProductMessage.vue').default,
)
Vue.component(
  'product-description',
  require('./components/products/ProductDescription.vue').default,
)
Vue.component(
  'product-image',
  require('./components/products/ProductImage.vue').default,
)
Vue.component(
  'product-image-avatar',
  require('./components/products/ProductImageAvatar.vue').default,
)
Vue.component(
  'product-image-carousel',
  require('./components/products/ProductImageCarousel.vue').default,
)
Vue.component(
  'product-name',
  require('./components/products/ProductName.vue').default,
)
Vue.component(
  'product-custom-review',
  require('./components/products/ProductCustomReview.vue').default,
)
Vue.component(
  'product-review',
  require('./components/products/ProductReview.vue').default,
)
Vue.component(
  'product-review-rating',
  require('./components/products/ProductReviewRating.vue').default,
)
Vue.component(
  'product-delivery-description',
  require('./components/products/ProductDeliveryDescription.vue').default,
)
Vue.component(
  'product-sku',
  require('./components/products/ProductSku.vue').default,
)
Vue.component(
  'product-order',
  require('./components/share/ProductOrder.vue').default,
)
Vue.component(
  'product-form',
  require('./components/products/ProductForm.vue').default,
)
Vue.component(
  'product-footer',
  require('./components/products/ProductFooter.vue').default,
)
Vue.component(
  'product-video',
  require('./components/products/ProductVideo.vue').default,
)

//EvoucherMainPage
Vue.component(
  'evoucher-main-page',
  require('./pages/EvoucherMainPage.vue').default,
)
Vue.component(
  'evoucher-instro',
  require('./components/evoucher/EvoucherInstro.vue').default,
)
Vue.component(
  'evoucher-condition',
  require('./components/evoucher/EvoucherCondition.vue').default,
)
Vue.component(
  'evoucher-form',
  require('./components/evoucher/EvoucherForm.vue').default,
)
Vue.component(
  'evoucher-name',
  require('./components/evoucher/EvoucherName.vue').default,
)
Vue.component(
  'evoucher-shop-detail',
  require('./components/evoucher/EvoucherShopDetail.vue').default,
)
Vue.component(
  'redeem-location',
  require('./components/evoucher/RedeemLocations.vue').default,
)
Vue.component('location', require('./components/evoucher/Location.vue').default)
Vue.component(
  'evoucher-qrcode',
  require('./components/evoucher/EvoucherQrCode.vue').default,
)

//OtpMainPage
Vue.component('vue-otp-input', OtpInput)
Vue.component(
  'otp-main-page',
  require('./pages/OtpVerificationMainPage.vue').default,
)
Vue.component('otp-header', require('./components/otps/OtpHeader.vue').default)
Vue.component(
  'otp-description',
  require('./components/otps/OtpDescription.vue').default,
)
Vue.component('otp-input', require('./components/otps/OtpInput.vue').default)
Vue.component(
  'salepage-popup',
  require('./components/otps/SalepagePopup.vue').default,
)

// OrderSummaryMainPage
Vue.component(
  'order-summary-main-page',
  require('./pages/OrderSummaryMainPage.vue').default,
)
Vue.component(
  'order-summary-header',
  require('./components/order-summary/OrderSummaryHeader.vue').default,
)
Vue.component(
  'order-description',
  require('./components/order-summary/OrderDescription.vue').default,
)
Vue.component(
  'order-summary',
  require('./components/order-summary/OrderSummary.vue').default,
)
Vue.component(
  'order-address',
  require('./components/order-summary/OrderAddress.vue').default,
)
Vue.component(
  'order-footer',
  require('./components/order-summary/OrderFooter.vue').default,
)

//QrCodePage
Vue.component(
  'qrcode-main-page',
  require('./pages/CreateQrCodeMainPage.vue').default,
)
Vue.component(
  'qrcode-header',
  require('./components/qr-code/QrCodeHeader.vue').default,
)
Vue.component(
  'qrcode-description',
  require('./components/qr-code/QrCodeDescription.vue').default,
)

//PaymentSuccessPage
Vue.component(
  'payment-success-main-page',
  require('./pages/PaymentSuccessMainPage.vue').default,
)
Vue.component(
  'payment-success-header',
  require('./components/payment-success/PaymentSuccessHeader.vue').default,
)
Vue.component(
  'payment-ordersn',
  require('./components/payment-success/PaymentOrderSn.vue').default,
)
Vue.component(
  'payment-description',
  require('./components/payment-success/PaymentDescription.vue').default,
)
Vue.component(
  'payment-download-app',
  require('./components/payment-success/PaymentDownloadApp.vue').default,
)

//RedeemLocation
Vue.component(
  'redeem-location-main-page',
  require('./pages/RedeemLocationMainPage.vue').default,
)
Vue.component(
  'redeem-location-header',
  require('./components/evoucher/RedeemLocationHeader.vue').default,
)
Vue.component(
  'location-redeem',
  require('./components/evoucher/LocationRedeem.vue').default,
)

// LineInfo
Vue.component(
  'line-info',
  require('./components/line-info/LineInfo.vue').default,
)

// Share sale-page
Vue.component(
  'thai-qr-pay-label',
  require('./components/share/ThaiQRPayLabel.vue').default,
)

Vue.component('snack-bar', require('./components/share/SnackBar.vue').default)
Vue.component(
  'share-footer-button',
  require('./components/share/FooterButton.vue').default,
)
Vue.component('share-header', require('./components/share/Header.vue').default)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
