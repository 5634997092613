<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    style="height: 50px"
  >
    <div class="container-fluid">
      <button class="btn rounded-0 btn-sm" @click="redirect">
        <img
          src="@/assets/icon-back.png"
          class="img-fluid thai-qr-image"
          width="13"
          alt="back-icon"
        />
      </button>
      <div class="mx-auto">
        <div class="title-header">ยืนยันหมายเลขโทรศัพท์</div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'OtpHeader',
  methods: {
    redirect() {
      window.history.back()
    },
  },
}
</script>

<style scoped>
.title-header {
  font-size: 24px;
}

@media screen and (max-width: 480px) {
  .title-header {
    font-size: 16px;
  }
}
</style>
