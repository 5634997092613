<template>
  <div class="thai-qr">
    <div class="thai-qr-title">
      <div>ชำระเงินผ่าน Thai QR Payment</div>
      <div>ไม่มีค่าธรรมเนียม</div>
    </div>
    <img
      src="@/assets/thaiQrPayment.svg"
      class="thai-qr-image"
      alt="thai-qr-payment"
    />
  </div>
</template>

<script>
export default {
  name: 'ThaiQRPayLabel',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.thai-qr {
  height: 68px;
  background-color: #0e3e68;
  display: flex;
  justify-content: center;

  &-title {
    color: white;
    justify-content: center;
    text-align: right;
    font-size: 12px;
    margin-top: 18px;
    margin-bottom: 18px;
    margin-right: 10px;
  }

  &-image {
    width: 120px;
    height: 48px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
