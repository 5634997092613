<template>
  <div id="QrCodeDescription">
    <div
      class="title-box d-flex align-items-center justify-content-center flex-column mb-4 mx-auto"
    >
      <div class="title-shop">ร้านค้า : Thira Venture Co.,Ltd.</div>
      <div class="title-quantity">จำนวน : THB {{ convertPriceString() }}</div>
    </div>

    <div class="title-verify text-center mb-4">
      กำลังตรวจสอบสถานะการชำระเงิน โปรดรอ...
    </div>

    <div class="title-plase-scan text-center mb-4">
      <div>โปรดแสกน QR จ่ายเงินให้เสร็จภายใน 30 นาที</div>
      <div>เหลือเวลา {{ min }}:{{ convertSecondString() }}</div>
    </div>

    <div class="box-qrcode mb-5">
      <img src="@/assets/thaiQrCode.png" class="ThaiQRCode img-fluid" />
      <img class="QR-img img-fluid p-3" :src="qrImageUrl" />
    </div>

    <share-footer-button
      class="container"
      msg="บันทึก QR"
      @click="downloadImage"
    ></share-footer-button>

    <div class="warning-qr-support text-center mt-4">
      <div>
        รองรับการชำระผ่าน App ของธนาคารเท่านั้น ยังไม่รองรับการชำระด้วย True
        Money Wallet และวิธีอื่นๆ
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moomallServices from '../../services/moomallServices'
import * as numeral from 'numeral'

export default {
  name: 'QrCodeDescription',
  data() {
    return {
      min: 0,
      sec: 0,
      expireDate: null,
      expireTime: null,
      baseUrlQrImage: process.env.VUE_APP_MOOMALL_FILE_URL,
      qrImageUrl: '',
    }
  },
  props: {
    qrCodeImageData: {
      type: Object,
      required: true,
    },
    productPaymentDetail: {
      type: Object,
      requred: true,
    },
  },
  created() {
    this.qrImageUrl = this.baseUrlQrImage + this.qrCodeImageData.qrImage
    this.expireDate = new Date(
      this.QrCodeData(
        `sp_${this.productPaymentDetail.spTimestamp}`,
      ).csExtExpiryTime.replace(' ', 'T'),
    )
    this.expireTime = this.expireDate.getTime()
    this.min = Math.floor((this.expireTime - new Date().getTime()) / 1000 / 60)
    this.sec =
      this.min === 30 ? 0 : Math.floor((this.expireTime % 60000) / 1000)
    this.countExpireTime()
  },
  computed: {
    ...mapGetters({
      OrderSummary: 'ProductStore/OrderSummary',
      QrCodeData: 'ProductStore/QrCodeData',
    }),
  },
  methods: {
    countExpireTime() {
      setInterval(async () => {
        if (this.sec === 0) {
          this.min--
          this.sec = 60
          if (this.min === 0) {
            this.min = 0
            this.sec = 0
          }
        }

        this.sec--
      }, 1000)
    },

    convertSecondString() {
      const secString = this.sec.toString().padStart(2, '0')
      return secString
    },

    convertPriceString() {
      const priceString = this.OrderSummary(
        `sp_${this.productPaymentDetail.spTimestamp}`,
      ).totalOrderPrice
      return numeral(priceString).format('0,0.00')
    },

    downloadImage() {
      moomallServices.downloadImage(this.qrImageUrl).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'qrcode.png')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
  },
}
</script>

<style scoped>
.title-box {
  max-width: 495px;
  height: 122px;
  background-color: #feefef;
  border-radius: 4px;
}

.title-shop {
  font-size: 21px;
}

.title-quantity {
  font-size: 25px;
  font-weight: Bold;
}

.title-verify {
  font-size: 20px;
  color: #535455;
}

.title-plase-scan {
  font-size: 20px;
  color: #dd3342;
}

.btn-qrcode {
  background-color: #ffffff;
  border: 1px solid #bcbcbc;
}

.btn-lg {
  height: 70px;
}

.box-qrcode {
  width: 225px;
  height: auto;
  align-items: center;
  display: block;
  margin: auto;
  border: 1px solid #707070;
}

.ThaiQRCode {
  height: 41px;
  width: 100%;
  margin: 0px;
}

.QR-img {
  width: 222px;
  height: 225px;
}

.warning-qr-support {
  font-size: 20px;
  color: #dd3342;
}

@media screen and (max-width: 480px) {
  .title-shop {
    font-size: 17px;
  }

  .title-quantity {
    font-size: 17px;
  }

  .title-verify {
    font-size: 15px;
  }

  .title-plase-scan {
    font-size: 15px;
  }

  .btn-lg {
    height: 45px;
  }

  .title-box {
    height: 100px;
  }

  .warning-qr-support {
    font-size: 15px;
  }
}
</style>
