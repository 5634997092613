var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"evoucher-form"},[_c('div',{staticClass:"evoucher-form__title"},[_c('div',[_vm._v("ข้อมูลผู้ซื้อ E-Voucher")]),_c('div',{staticClass:"evoucher-form__title--gray",on:{"click":_vm.gotoPDPA}},[_vm._v(" นโยบายความเป็นส่วนตัว "),_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icon-next-gray.svg"),"width":"6","height":"11","alt":"Icon Next"}})])]),_c('div',{staticClass:"evoucher-form__input mb-2"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"evoucher-form__input-control col-6"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
              'is-invalid': _vm.$v.name.$error,
              'is-valid': !_vm.$v.name.$invalid,
            },attrs:{"type":"text","id":"firstNameInput","placeholder":"กรอกชื่อ - สกุล","disabled":_vm.previewId == 0,"value":""},domProps:{"value":(_vm.$v.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"evoucher-form__input-control col-6"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.tel.$model),expression:"$v.tel.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
              'is-invalid': _vm.$v.tel.$error,
              'is-valid': !_vm.$v.tel.$invalid,
            },attrs:{"placeholder":"กรอกเบอร์โทรศัพท์มือถือ","type":"tel","value":"","id":"tel-input","pattern":"[0-9]{3}-[0-9]{2}-[0-9]{3}","disabled":_vm.previewId == 0},domProps:{"value":(_vm.$v.tel.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.tel, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"evoucher-form__input-control col-12"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
              'is-invalid': _vm.$v.email.$error,
              'is-valid': !_vm.$v.email.$invalid,
            },attrs:{"type":"text","id":"inputEmail4","placeholder":"กรอกอีเมลของคุณ","disabled":_vm.previewId == 0,"value":""},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('snack-bar',{attrs:{"message":_vm.message,"icon":"mdi-information-outline"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}})],1),_c('product-footer',{attrs:{"previewId":_vm.previewId,"soldOut":_vm.product.productStock,"state":_vm.state,"offshelf":_vm.offshelf,"lineAccount":_vm.productCoach.line_account},on:{"onSubmit":_vm.onSubmit}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"firstNameInput"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" ชื่อ - สกุล ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"tel-input"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" เบอร์โทรศัพท์ผู้รับสินค้า ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"inputEmail4"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" อีเมล "),_c('span',{staticClass:"email-notice"},[_vm._v("(หลักฐานคำสั่งซื้อจะถูกส่งไปยังอีเมลนี้)")])])}]

export { render, staticRenderFns }