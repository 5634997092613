export const ProductStore = {
  namespaced: true,
  state: {
    productDetail: {},
    userInfo: {},
    orderSummary: {},
    qrCodeData: {},
    addressShipping: {},
    PDPA: null,
  },
  actions: {},
  mutations: {
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
    },
    SET_PRODUCT_DETAIL: (state, { data, time }) => {
      state.productDetail[`sp_${time}`] = data
    },
    SET_ORDER_SUMMARY: (state, { data, id }) => {
      state.orderSummary[id] = data
    },
    SET_QRCODE_DATA: (state, { data, id }) => {
      state.qrCodeData[id] = data
    },
    SET_ADDRESS_SHIPPING: (state, { data, id }) => {
      state.addressShipping[id] = data
    },
    SET_COMFIRM_PDPA: (state, value) => {
      state.PDPA = value
    },
    REMOVE_ORDER_PAID: (state, spTimestamp) => {
      delete state.productDetail[spTimestamp]
      delete state.orderSummary[spTimestamp]
      delete state.qrCodeData[spTimestamp]
      delete state.addressShipping[spTimestamp]
    },
    REMOVE_USER_INFO: (state, address) => {
      if (address) {
        return (state.userInfo = address)
      }
      state.userInfo = {}
    },
  },
  getters: {
    ProductDetail: state => id => {
      return state.productDetail[id]
    },
    UserInfo: state => state.userInfo,
    OrderSummary: state => id => {
      return state.orderSummary[id]
    },
    QrCodeData: state => id => {
      return state.qrCodeData[id]
    },
    AddressShipping: state => id => {
      return state.addressShipping[id]
    },
    PDPA: state => {
      return state.PDPA
    },
  },
}
