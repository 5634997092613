<template>
  <div
    id="OtpVerificationMainPage"
    v-if="
      this.products && this.ProductDetail(`sp_${this.products.spTimestamp}`)
    "
  >
    <otp-header></otp-header>
    <main>
      <otp-description
        class="otp-description py-2 container"
        :productCoach="productCoach"
        :qrCodeUrl="qrCodeUrl"
        :products="products"
      >
      </otp-description>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moomallService from '../services/moomallServices'

export default {
  name: 'OtpVerificationMainPage',
  data() {
    return {
      products: null,
      productCoach: null,
      qrCodeUrl: null,
    }
  },
  computed: {
    ...mapGetters({ ProductDetail: 'ProductStore/ProductDetail' }),
  },
  async mounted() {
    const { data } = await moomallService.get(
      `/product_coach/name/${this.$route.params['salepageName']}`,
    )
    this.products = this.$route.query

    const { productCoach } = data

    this.productCoach = productCoach
    this.qrCodeUrl = `/${this.$route.params['salepageName']}/generate_qr_code`

    if (this._.isEmpty(this.ProductDetail(`sp_${this.products.spTimestamp}`))) {
      return (location.href = `/${this.productCoach.route_name}`)
    }

    // GTAG
    this.$gtag.pageview({
      page_title: this.productCoach.welcome_title + '- otp',
      page_location: window.location.href,
      page_path: this.productCoach.route_name,
    })
  },
}
</script>

<style scoped>
.otp-description {
  background-color: #ffffff;
  height: 100vh;
  margin-top: -50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
</style>
