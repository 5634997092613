<template>
  <div class="product-order container">
    <div class="product-order-title mb-2">
      สรุปคำสั่งซื้อ ({{ productOrder.selectNumTotal }}รายการ)
    </div>

    <div class="product-order-total d-flex justify-content-between my-auto">
      <div>ยอดรวม</div>
      <div>฿{{ totalPrice }}</div>
    </div>

    <!-- NOTE: From design we should show freigh & discount in main page but freigh &
    discount will calculate in confirmation page -->
    <!-- <div class="product-order-freigh d-flex justify-content-between my-auto">
      <div>ค่าจัดส่ง:</div>
      <div>฿0</div>
    </div>

    <div class="product-order-discount d-flex justify-content-between my-auto">
      <div>ส่วนลด</div>
      <div>฿0</div>
    </div> -->

    <div
      class="product-order-grandtotal d-flex justify-content-between my-auto"
    >
      <div>ยอดรวมทั้งหมด</div>
      <div class="product-order-grandtotal-price">฿{{ totalPrice }}</div>
    </div>
  </div>
</template>

<script>
import * as numeral from 'numeral'

export default {
  name: 'ProductOrder',
  props: {
    productOrder: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    totalPrice() {
      const price = this.productOrder.price * this.productOrder.selectNumTotal

      return numeral(price).format('0,0.00')
    },
  },
}
</script>

<style lang="scss" scoped>
.product-order {
  border-radius: 10px !important;
  padding: 1rem;
  color: #453d3d !important;
  font-size: 23px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  &-title {
    font-weight: bold;
    border-bottom: 0.5px solid #fbe7e7;
    border-radius: 0px !important;
    padding: 0.5rem 0;
  }

  &-grandtotal {
    font-size: 25px;
    font-weight: bold;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }

    &-price {
      color: #da3d3c;
    }
  }
}
</style>
