<template>
  <div class="popup-confirm">
    <div class="mask" @click="toggleModal" :class="{ show: showModal }"></div>
    <div class="modal-wrap" v-if="showModal" :class="{ show: showModal }">
      <div class="confirm-tips">
        เนื่องจากคุณมีข้อมูลอยู่ในระบบแล้ว
        หากคุณต้องการสั่งซื้อสินค้าเพื่อเป็นเพเซอร์สามารถติดต่อฝ่าย บริการลูกค้า
        <div>
          <a href="tel:+6621146595">02-1146595</a>
          ต่อ 1
        </div>
      </div>
      <div class="btn-group">
        <button
          @click="comfirmDel"
          type="button"
          class="btn btn-outline-danger"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    productCoach: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggleModal')
      window.history.back()
    },
    async comfirmDel() {
      this.toggleModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-confirm {
  position: relative;
  z-index: 999;
}

.mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.modal-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translate(-50%, -50%);
  max-width: 300px;
  line-height: 48px;
  font-size: 15px;
  border-radius: 10px;
  text-align: center;
  z-index: 3000;
  background: #fff;
  padding: 15px;

  .confirm-tips {
    margin: 0 0 25px 0;
    color: #000;
    word-break: break-word;
  }

  .btn-group {
    display: flex;
  }

  .icon-check {
    margin: 20px auto 0 auto;
    width: 48px;
    height: 48px;
  }

  .btn-cancel {
    flex: 1;
    font-size: 16px;
    border: 1px solid #bcbcbc;
    height: 44px;
    color: #000;
    margin-right: 10px;
  }

  .btn-del {
    flex: 1;
    font-size: 15px;
    background-color: #da1f28;
    height: 44px;
    color: #fff;
  }
}

.mask.show {
  visibility: visible;
  opacity: 1;
}

.modal-wrap.show {
  opacity: 1;
}
</style>
