<template>
  <div id="ProductDescription" class="container">
    <div class="description" v-html="productDescription"></div>
  </div>
</template>

<script>
export default {
  name: 'ProductDescription',
  props: {
    productDescription: {
      type: String,
    },
  },
  data() {
    return {}
  },
}
</script>

<style>
.description {
  font-size: 20px;
}

.description img {
  width: 100% !important;
}

.description span {
  font-size: 20px !important;
  font-family: 'Prompt', sans-serif !important;
}

@media screen and (max-width: 480px) {
  .description {
    font-size: 15px;
  }

  .description span {
    font-size: 15px !important;
  }
}
</style>
