<template>
  <div class="evoucher-form">
    <div class="evoucher-form__title">
      <div>ข้อมูลผู้ซื้อ E-Voucher</div>
      <div @click="gotoPDPA" class="evoucher-form__title--gray">
        นโยบายความเป็นส่วนตัว
        <img
          src="@/assets/icon-next-gray.svg"
          class="ml-1"
          width="6"
          height="11"
          alt="Icon Next"
        />
      </div>
    </div>
    <div class="evoucher-form__input mb-2">
      <form @submit.prevent>
        <div class="form-row">
          <div class="evoucher-form__input-control col-6">
            <label for="firstNameInput">
              <span class="text-danger">*</span>
              ชื่อ - สกุล
            </label>
            <input
              type="text"
              class="form-control"
              id="firstNameInput"
              v-model.trim="$v.name.$model"
              :class="{
                'is-invalid': $v.name.$error,
                'is-valid': !$v.name.$invalid,
              }"
              placeholder="กรอกชื่อ - สกุล"
              :disabled="previewId == 0"
              value=""
            />
          </div>

          <div class="evoucher-form__input-control col-6">
            <label for="tel-input">
              <span class="text-danger">*</span>
              เบอร์โทรศัพท์ผู้รับสินค้า
            </label>
            <input
              class="form-control"
              placeholder="กรอกเบอร์โทรศัพท์มือถือ"
              type="tel"
              value=""
              id="tel-input"
              v-model.trim="$v.tel.$model"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              :class="{
                'is-invalid': $v.tel.$error,
                'is-valid': !$v.tel.$invalid,
              }"
              :disabled="previewId == 0"
            />
          </div>

          <div class="evoucher-form__input-control col-12">
            <label for="inputEmail4">
              <span class="text-danger">*</span>
              อีเมล
              <span class="email-notice"
                >(หลักฐานคำสั่งซื้อจะถูกส่งไปยังอีเมลนี้)</span
              >
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              v-model.trim="$v.email.$model"
              :class="{
                'is-invalid': $v.email.$error,
                'is-valid': !$v.email.$invalid,
              }"
              placeholder="กรอกอีเมลของคุณ"
              :disabled="previewId == 0"
              value=""
            />
          </div>
        </div>
      </form>
      <snack-bar
        v-model="snackbar"
        :message="message"
        icon="mdi-information-outline"
      />
    </div>

    <product-footer
      :previewId="previewId"
      @onSubmit="onSubmit"
      :soldOut="product.productStock"
      :state="state"
      :offshelf="offshelf"
      :lineAccount="productCoach.line_account"
    >
    </product-footer>
  </div>
</template>

<script>
import { email, required, maxLength, helpers } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ProductForm',
  props: {
    createOrderUrl: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    productCoach: {
      type: Object,
      required: true,
    },
    previewId: {
      type: [Number, String],
      default: 1,
    },
    state: {
      type: [Number, String],
      required: true,
    },
    offshelf: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      UserInfo: 'ProductStore/UserInfo',
      PDPA: 'ProductStore/PDPA',
    }),
  },
  mounted() {
    if (Number(this.$route.query?.previewId) !== 0) {
      this.name = this.UserInfo.name || ''
      this.email = this.UserInfo.email || ''
      this.tel = this.UserInfo.tel || ''
    }

    if (!this.PDPA) {
      this.removeUserInfo()
    }
  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      timestamp: '',
      snackbar: false,
      message: '',
    }
  },
  methods: {
    ...mapMutations({
      setUserInfo: 'ProductStore/SET_USER_INFO',
      setProductDetail: 'ProductStore/SET_PRODUCT_DETAIL',
      setAddressShipping: 'ProductStore/SET_ADDRESS_SHIPPING',
      removeUserInfo: 'ProductStore/REMOVE_USER_INFO',
    }),
    async onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return this.validateHandler()
      } else {
        if (this.product.selectNumTotal > 0) {
          let userInfo = {
            name: this.name,
            email: this.email,
            tel: this.tel,
          }

          await this.setUserInfo(userInfo)

          this.timestamp = this.$route.query.spTimestamp
            ? this.$route.query.spTimestamp
            : Date.now()

          await this.setProductDetail({
            data: this.product,
            time: this.timestamp,
          })
          this.setAddressShipping({
            data: this.UserInfo,
            id: `sp_${this.timestamp}`,
          })

          const orderVoucher = this.prepareDataVoucher(this.timestamp)
          this.$router.push({
            path: this.createOrderUrl,
            query: {
              ...orderVoucher,
            },
          })
        }
      }
    },
    prepareDataVoucher(timestamp) {
      return {
        productId: this.product.productId,
        inviteCode: this.productCoach.invite_code,
        productGoodsId: this.product.id,
        number: this.product.selectNumTotal,
        mobile: this.tel,
        receiver: this.name,
        email: this.email,
        spTimestamp: timestamp,
      }
    },
    validateHandler() {
      // NOTE: Manual check and alert popup, True = valid | False = invalid
      // Name section
      if (!this.$v.name.required) {
        return this.snackbarHandler('กรุณากรอกข้อมูลผู้ซื้อ')
      }
      if (!this.$v.name.maxLength) {
        return this.snackbarHandler(
          `ห้ามกรอกชื่อ - สกุล เกิน ${this.$v.name.$params.maxLength.max} ตัว`,
        )
      }

      // Phone section
      if (!this.$v.tel.required) {
        return this.snackbarHandler('กรุณากรอกเบอร์โทรศัพท์มือถือ')
      }
      if (!this.$v.tel.isTelValid) {
        return this.snackbarHandler('กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง')
      }

      // Email section
      if (!this.$v.email.required) {
        return this.snackbarHandler('กรุณากรอกอีเมลของคุณ')
      }
      if (!this.$v.email.email) {
        return this.snackbarHandler('กรุณาระบุอีเมลให้ถูกต้อง')
      }
    },
    snackbarHandler(message) {
      this.snackbar = true
      this.message = message
    },
    gotoPDPA() {
      this.$router.push('/pdpa')
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(128),
    },
    email: {
      required,
      email,
    },
    tel: {
      required,
      isTelValid: helpers.regex('isTelValid', /^[0](?:[0-9] ?){8}[0-9]$/),
    },
  },
}
</script>

<style lang="scss">
.evoucher-form {
  border-radius: 10px 10px 0 0;
  padding: 1rem 1rem 0.5rem 1rem;

  &__title {
    display: flex;
    justify-content: space-between;

    font-size: 29px;
    text-align: center;
    color: #333333;
    border-bottom: 0.5px solid #fbe7e7;
    padding-bottom: 6px;

    &--gray {
      color: #bebebe;
    }
  }

  &__input {
    &-control {
      padding-top: 4px;
      padding-bottom: 4px;

      .email-notice {
        font-size: 24px;
        color: #da3d3c;
      }
    }

    .form-control {
      font-size: 29px;
      background-color: #fffafa;
    }

    .form-control {
      border: 1px solid #fcefef;
      border-radius: 14px;
    }

    .form-control:focus {
      border-color: #fcefef;
      box-shadow: 0 0 0 0.2rem #fcefef;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
      background-image: none;
      border-color: #fcefef;
    }

    .form-control.is-valid:focus {
      box-shadow: 0 0 0 0.2rem rgba($color: #fcefef, $alpha: 0.75);
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: none;
      border-color: #453d3d;
    }

    .form-control.is-invalid:focus {
      box-shadow: 0 0 0 0.2rem rgba($color: #453d3d, $alpha: 0.5);
    }

    label {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  .evoucher-form {
    &__title {
      font-size: 12px;
    }

    &__input {
      &-control {
        .email-notice {
          font-size: 10px;
        }
      }

      .form-control {
        font-size: 14px;
        height: 44px;
      }

      label {
        font-size: 10px;
      }
    }
  }
}
</style>
