<template>
  <div class="line" @click="goToLine(lineAccount)">
    <img
      class="line__btn"
      src="@/assets/line/button.svg"
      width="135.5"
      height="49.5"
    />
    <div class="line__text">ติดต่อ</div>
    <br />
  </div>
</template>

<script>
export default {
  name: 'LineInfo',
  props: {
    previewId: {
      type: [Number, String],
      default: 1,
    },
    lineAccount: {
      type: String,
      required: false,
    },
  },
  methods: {
    goToLine(idAccount) {
      if (this.previewId !== 0) {
        open(`http://line.me/ti/p/~${idAccount}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.line {
  position: relative;

  &__text {
    position: absolute;
    right: 22.5px;
    top: 11px;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
