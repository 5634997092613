<template>
  <v-card class="location" :class="border ? 'location-border' : ''">
    <v-row>
      <v-col class="text-center" :cols="imgCol">
        <product-image class="location-image" :pathImage="img"></product-image>
      </v-col>
      <v-col class="location-detail my-auto" :cols="addressCol">
        <div class="location-detail-title">
          {{ name }}
        </div>
        <div class="location-detail-address mb-1">
          {{ address }}
        </div>
        <div class="location-detail-phone mb-1">โทร: {{ phone }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'location',
  props: {
    img: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    imgCol: {
      type: Number,
      default: 4,
    },
    addressCol: {
      type: Number,
      default: 8,
    },
  },
}
</script>

<style lang="scss" scoped>
.location {
  display: flex;
  border-radius: 14px !important;
  box-shadow: none !important;

  &-border {
    border: 1px solid #eaeaea !important;
  }

  &-image {
    min-width: 90px;
    min-height: 90px;

    width: 90px;
    height: 90px;
    margin: 0 !important;
  }

  &-detail {
    font-size: 22px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }

    &-title {
      font-weight: bold;
      color: #453d3d;
    }

    &-address {
      color: #bebebe;
    }

    &-phone {
      color: #bebebe;
    }
  }
}
</style>
