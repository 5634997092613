<template>
  <div id="ProductName">
    <div
      class="product-price mb-3"
      v-if="
        propductName.isFlash == 0 &&
        propductName.skuMinPrice != propductName.skuMaxPrice
      "
    >
      ฿{{ propductName.skuMinPrice }}~{{ propductName.skuMaxPrice }}
    </div>
    <div
      class="product-price mb-3"
      v-if="
        propductName.isFlash == 0 &&
        propductName.skuMinPrice == propductName.skuMaxPrice
      "
    >
      ฿{{ propductName.skuMinPrice }}
    </div>
    <div class="product-name">{{ propductName.name1 }}</div>
    <div class="subtitle-product-name">{{ propductName.name2 }}</div>
    <div
      class="product-shipping"
      v-if="
        propductName.freeFreightAmount == null ||
        propductName.freeFreightAmount == 0
      "
    >
      จัดส่งฟรี
    </div>
    <div class="product-shipping" v-if="propductName.freeFreightAmount > 0">
      จัดส่งฟรีเมื่อซื้อเกิน {{ propductName.freeFreightAmount }} บาท
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductName',
  props: {
    propductName: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.product-price {
  font-size: 40px;
  color: #da1f28;
}

.product-name {
  font-size: 30px;
}

.subtitle-product-name {
  font-size: 24px;
  color: #999999;
}

.product-shipping {
  font-size: 25px;
  color: #999999;
}

@media screen and (max-width: 480px) {
  .product-price {
    font-size: 20px;
  }

  .product-name {
    font-size: 19px;
  }

  .subtitle-product-name {
    font-size: 15px;
  }

  .product-shipping {
    font-size: 13px;
  }
}
</style>
